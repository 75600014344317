// eloyalty section
$loyalty-eloyalty-dashboard-section-background: color(light) !default;
$loyalty-eloyalty-dashboard-section-gutters: rem-calc(20) !default;
$loyalty-eloyalty-dashboard-section-padding: 0 !default;
$loyalty-eloyalty-dashboard-section-padding--large: rem-calc(15 $loyalty-eloyalty-dashboard-section-gutters) !default;

$loyalty-eloyalty-dashboard-section-height: 100% !default;
$loyalty-eloyalty-dashboard-points-size: rem-calc(20) !default;
$loyalty-eloyalty-dashboard-points-size--large: rem-calc(20) !default;
$loyalty-eloyalty-dashboard-points-text-font-size: rem-calc(12) !default;
$loyalty-eloyalty-dashboard-points-text-font-size--large: rem-calc(16) !default;
$loyalty-eloyalty-dashboard-points-text-margin: rem-calc(0 0 0 5) !default;
$loyalty-eloyalty-dashboard-text-align-center: center !default;
$loyalty-eloyalty-dashboard-button-padding-right: rem-calc(12) !default;
$loyalty-eloyalty-dashboard-button-margin-bottom: 1rem !default;
$loyalty-eloyalty-dashboard-text-color-gray: #a6a6a6 !default;
$loyalty-eloyalty-dashboard-align-center: center !default;
$loyalty-eloyalty-dashboard-justify-center: center !default;
$loyalty-eloyalty-dashboard-display-flex: flex !default;
$loyalty-eloyalty-dashboard-hamburger-mobile-background-color: #d9d9d9 !default;
$loyalty-eloyalty-dashboard-hamburger-mobile-section-padding: rem-calc(0 10) !default;

$loyalty-eloyalty-dashboard-progressbar-wrapper-width: rem-calc(119) !default;
$loyalty-eloyalty-dashboard-progressbar-wrapper-height: $loyalty-eloyalty-dashboard-progressbar-wrapper-width !default;
$loyalty-eloyalty-dashboard-progressbar-wrapper-width--large: rem-calc(150) !default;
$loyalty-eloyalty-dashboard-progressbar-wrapper-height--large: $loyalty-eloyalty-dashboard-progressbar-wrapper-width--large !default;
$loyalty-eloyalty-dashboard-progressbar-width: 100% !default;
$loyalty-eloyalty-dashboard-progressbar-height: 100% !default;
$loyalty-eloyalty-dashboard-progressbar-top: 0 !default;
$loyalty-eloyalty-dashboard-progressbar-left: 50% !default;
$loyalty-eloyalty-dashboard-progressbar-left--large: 0 !default;
$loyalty-eloyalty-dashboard-progressbar-transform: -50% !default;
$loyalty-eloyalty-dashboard-progressbar-transform--large: 0 !default;
$loyalty-eloyalty-dashboard-row-mobile-margin: rem-calc(0 0 20 0) !default;

$loyalty-eloyalty-dashboard-tier-image-height: 100% !default;
$loyalty-eloyalty-dashboard-tier-image-width: $loyalty-eloyalty-dashboard-tier-image-height !default;
$loyalty-eloyalty-dashboard-tier-image-height--large: rem-calc(150) !default;
$loyalty-eloyalty-dashboard-tier-image-width--large: $loyalty-eloyalty-dashboard-tier-image-height--large !default;
$loyalty-eloyalty-dashboard-tier-image-padding: rem-calc(7) !default;

$loyalty-eloyalty-dashboard-tier-info-flex-direction: column !default;
$loyalty-eloyalty-dashboard-tier-info-font-size: rem-calc(14) !default;
$loyalty-eloyalty-dashboard-tier-info-white-space: nowrap !default;

$loyalty-eloyalty-dashboard-tier-amount-font-size: rem-calc(14) !default;
$loyalty-eloyalty-dashboard-tier-amount-font-size--large: rem-calc(16) !default;

$loyalty-eloyalty-dashboard-points-font-size: rem-calc(14) !default;
$loyalty-eloyalty-dashboard-points-font-size--large: rem-calc(14) !default;

$loyalty-eloyalty-dashboard-button-link-font-size: null !default;
$loyalty-eloyalty-dashboard-button-link-font-size--large: null !default;

@mixin apaclayer-components-eloyalty-dashboard {
    .l-eloyalty-section {
        background: $loyalty-eloyalty-dashboard-section-background;
        padding: $loyalty-eloyalty-dashboard-section-padding;
        height: $loyalty-eloyalty-dashboard-section-height;

        @include breakpoint(medium up) {
            padding: $loyalty-eloyalty-dashboard-section-padding--large;
        }
    }

    .l-eloyalty-points {
        font-weight: bold;
        font-size: $loyalty-eloyalty-dashboard-points-size;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(large) {
            font-size: $loyalty-eloyalty-dashboard-points-size--large;
        }

        .l-eloyalty-points__text {
            font-size: $loyalty-eloyalty-dashboard-points-text-font-size;
            margin: $loyalty-eloyalty-dashboard-points-text-margin;

            @include breakpoint(large) {
                font-size: $loyalty-eloyalty-dashboard-points-text-font-size--large;
            }
        }
    }

    .l-eloyalty-align-center {
        text-align: $loyalty-eloyalty-dashboard-text-align-center;
    }

    .l-eloyalty-text-color {
        color: $loyalty-eloyalty-dashboard-text-color-gray;
    }

    .l-eloyalty-points-wrapper {
        display: $loyalty-eloyalty-dashboard-display-flex;
        justify-content: $loyalty-eloyalty-dashboard-justify-center;
        font-size: $loyalty-eloyalty-dashboard-points-font-size;

        @include breakpoint(large) {
            font-size: $loyalty-eloyalty-dashboard-points-font-size--large;
        }
    }

    .l-eloyalty-points-container {
        align-self: $loyalty-eloyalty-dashboard-align-center;
    }

    .m-eloyalty-dashboard {
        background-color: $loyalty-eloyalty-dashboard-hamburger-mobile-background-color;

        &.c-hamburger__item:not(.m-navigation):not(.m-account):not(.m-localeselector) {
            padding: $loyalty-eloyalty-dashboard-hamburger-mobile-section-padding;
        }

        .l-hamburger__eloyalty-dashboard {
            align-items: center;
        }
    }

    @include breakpoint(small only) {
        .l-eloyalty-row-mobile {
            background: $loyalty-eloyalty-dashboard-section-background;
            margin: $loyalty-eloyalty-dashboard-row-mobile-margin;
        }

        .l-eloyalty-button {
            padding-right: $loyalty-eloyalty-dashboard-button-padding-right;
        }
    }

    @include breakpoint(medium) {
        .l-eloyalty-button {
            margin-bottom: $loyalty-eloyalty-dashboard-button-margin-bottom;
        }
    }

    .l-eloyalty__progressbar-wrapper {
        position: relative;
        width: $loyalty-eloyalty-dashboard-progressbar-wrapper-width;
        height: $loyalty-eloyalty-dashboard-progressbar-wrapper-height;
        border-radius: 100%;
        overflow: hidden;
        
        @include breakpoint(large) {
            width: $loyalty-eloyalty-dashboard-progressbar-wrapper-width--large;
            height: $loyalty-eloyalty-dashboard-progressbar-wrapper-height--large;
        }

        .l-eloyalty__progressbar {
            order: 1;
            position: absolute;
            left: $loyalty-eloyalty-dashboard-progressbar-left;
            transform: translateX($loyalty-eloyalty-dashboard-progressbar-transform) rotate(-90deg);
            top: $loyalty-eloyalty-dashboard-progressbar-top;
            width: $loyalty-eloyalty-dashboard-progressbar-width;
            height: $loyalty-eloyalty-dashboard-progressbar-height;

            @include breakpoint(large) {
                left: $loyalty-eloyalty-dashboard-progressbar-left--large;
                transform: translateX($loyalty-eloyalty-dashboard-progressbar-transform--large) rotate(-90deg);
            }
        }

        .l-eloyalty__tier-image {
            width: $loyalty-eloyalty-dashboard-progressbar-width;
            height: $loyalty-eloyalty-dashboard-progressbar-height;
            padding: $loyalty-eloyalty-dashboard-tier-image-padding;
            border-radius: 100%;
        }
    }

    .l-eloyalty__tier-info {
        flex-direction: $loyalty-eloyalty-dashboard-tier-info-flex-direction;
        font-size: $loyalty-eloyalty-dashboard-tier-info-font-size;
        white-space: $loyalty-eloyalty-dashboard-tier-info-white-space;
    }

    .l-eloyalty__tier-amount {
        font-size: $loyalty-eloyalty-dashboard-tier-amount-font-size;

        @include breakpoint(large) {
            font-size: $loyalty-eloyalty-dashboard-tier-amount-font-size--large;
        }
    }

    .m-eloyalty-button-link {
        font-size: $loyalty-eloyalty-dashboard-button-link-font-size;

        @include breakpoint(large) {
            font-size: $loyalty-eloyalty-dashboard-button-link-font-size--large;
        }
    }
}
