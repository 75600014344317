$third-party-whatsapp-bottom: rem-calc(180) !default;
$third-party-whatsapp-width: rem-calc(70) !default;
$third-party-whatsapp-width--large: rem-calc(100) !default;
$third-party-whatsapp-close-size: rem-calc(30) !default;
$third-party-whatsapp-close-top: rem-calc(-20) !default;
$third-party-whatsapp-close-top--large: rem-calc(-10) !default;
$third-party-whatsapp-close-right: 0 !default;
$third-party-whatsapp-close-color: color(text) !default;

@mixin apac-level-third-party-whatsapp {
    /* stylelint-disable*/
    .whatsapp-chat {
        position: fixed;
        bottom: $third-party-whatsapp-bottom;
        width: $third-party-whatsapp-width;
        #{$global-right}: 0;

        @include breakpoint(large) {
            width: $third-party-whatsapp-width--large;
        }

        .whatsapp-chat-close {
            position: absolute;
            top: $third-party-whatsapp-close-top;
            right: $third-party-whatsapp-close-right;
            color: $third-party-whatsapp-close-color;
            font-size: $third-party-whatsapp-close-size;
            line-height: 1;

            @include breakpoint(large) {
                top: $third-party-whatsapp-close-top--large;
            }
        }
    }
}
