$header-sticky-background: color(light);
$header-sticky-hamburger-background: color(light);
$header-sticky-search-button-icon: search;
$header-sticky-search-button-icon-color: color(dark);

@mixin staffshop-layout-header-sticky {
    @include breakpoint(medium down) {
        .l-header.m-sticked {
            .l-header__inner {
                .l-header__main-area {
                    background: $header-sticky-background;
                }

                .l-header__hamburger {
                    background: $header-sticky-hamburger-background;
                }

                .l-header__search-button::after {
                    @include svg-icon($header-sticky-search-button-icon, $header-sticky-search-button-icon-color, 100%, left, no-repeat, true);
                }
            }
        }
    }
}