$loyalty-faq-wrapper-margin: rem-calc(20 0 0) !default;
$loyalty-faq-wrapper-text-align: center !default;
$loyalty-faq-text-transform: uppercase !default;

@mixin apaclayer-components-loyalty-faq {
    .c-loyalty-faq__wrapper {
        margin: $loyalty-faq-wrapper-margin;
        text-align: $loyalty-faq-wrapper-text-align;
    }

    .c-loyalty-faq__subtitle {
        text-transform: $loyalty-faq-text-transform;
    }
}
