$loyalty-availablerewards-tier-container: rem-calc(0 0 30);
$loyalty-availablerewards-button-margin: rem-calc(20 0 10);
$loyalty-availablerewards-button-margin--large: rem-calc(30 0 0);

@mixin apaclayer-components-loyalty-availablerewards {
    .c-loyalty-availablerewards__tier-container {
        margin: $loyalty-availablerewards-tier-container;
    }

    .c-loyalty-availablerewards__button {
        margin: $loyalty-availablerewards-button-margin;

        @include breakpoint(large) {
            margin: $loyalty-availablerewards-button-margin--large;
        }
    }
}
