$loyalty-recycling-history-line-height: 1 !default;
$loyalty-recycling-history-totalrecyclecount-font-size: 4rem;
$loyalty-recycling-history-msgsection-font-size: 1rem;
$loyalty-recycling-history-color: color(dark) !default;
$loyalty-recycling-history-totalrecyclecount-color: #006400;
$loyalty-recycling-history-font-family: $font-primary !default;
$loyalty-recycling-history-msgsection-align: center !default;

@mixin apaclayer-components-loyalty-recycling-history {
    .c-loyalty-recycling-history__breakline {
        width: 50%;
    }

    .c-loyalty-recycling-history__section {
        color: $loyalty-recycling-history-color;
        display: flex;
        flex-wrap: nowrap;
        justify-content: $loyalty-recycling-history-msgsection-align;
        margin-top: 2rem;
    }

    .c-loyalty-recycling-history__stampsmessage {
        letter-spacing: 0.1rem;
        line-height: 1.2;
    }

    .c-loyalty-recycling-history__imgsection {
        margin-right: 2rem;
    }

    .c-loyalty-recycling-history__msgsection {
        text-align: $loyalty-recycling-history-msgsection-align;
        font-family: $loyalty-recycling-history-font-family;
        font-weight: bolder;
        font-size: $loyalty-recycling-history-msgsection-font-size;
    }

    .c-loyalty-recycling-history__totalrecyclecount {
        line-height: $loyalty-recycling-history-line-height;
        font-size: $loyalty-recycling-history-totalrecyclecount-font-size;
        font-weight: bold;
        color: $loyalty-recycling-history-totalrecyclecount-color;
    }
}