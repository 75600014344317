/*
* Styling for Line Messenger Widget
*/

$line-messenger-widget-color-white: #fff;
$line-messenger-widget-color-line-background: #00b900;
$line-messenger-widget-chat-widget-index: 20;
$line-messenger-widget-chat-close-size: 16px;
$line-messenger-widget-chat-close-top: rem-calc(20) !default;
$line-messenger-widget-chat-close-right: rem-calc(20) !default;
$line-messenger-widget-chat-icon-size: rem-calc(14) !default;
$line-messenger-widget-chat-desc-size: rem-calc(18) !default;
$line-messenger-widget-chat-desc-line-height: rem-calc(24) !default;
$line-messenger-widget-chat-descsub-size: rem-cacl(12) !default;
$line-messenger-widget-chat-descsub-line-height: rem-calc(20) !default;
$line-messenger-widget-chat-widget-position-right: rem-calc(20) !default;
$line-messenger-widget-chat-widget-position-bottom: rem-calc(230) !default;
$line-messenger-widget-chat-widget-width: rem-calc(0) !default;
$line-messenger-widget-chat-widget-height: rem-calc(0) !default;
$line-messenger-widget-qr-code-width: null !default;
$line-messenger-widget-chat-window-width: rem-calc(260) !default;
$line-messenger-widget-chat-window-text-align: null !default;
$line-messenger-widget-chat-window-body-align-items: null !default;
$line-messenger-widget-chat-window-body-padding: rem-calc(20) !default;
$line-messenger-widget-chat-widget-bottom: rem-calc(90) !default;
$line-messenger-widget-chat-widget-bottom--medium-down: rem-calc(40) !default;
$line-messenger-widget-chat-widget-right: rem-calc(85) !default;
$line-messenger-widget-chat-widget-right--medium-down: rem-calc(-20) !default;
$line-messenger-widget-chat-widget-height: rem-calc(200) !default;
$line-messenger-widget-chat-window-close-top: rem-calc(12) !default;
$line-messenger-widget-chat-window-close-right: rem-calc(8) !default;
$line-messenger-widget-chat-window-image-width: rem-calc(22) !default;
$line-messenger-widget-chat-window-image-height: rem-calc(22) !default;
$line-messenger-widget-chat-window-image-margin: rem-calc(0 3 0 12) !default;

@mixin apac-components-line-messenger-widget {
    .c-linemessenger-widget {
        position: fixed;
        width: $line-messenger-widget-chat-widget-width;
        height: $line-messenger-widget-chat-widget-height;
        bottom: $line-messenger-widget-chat-widget-position-bottom;
        right: $line-messenger-widget-chat-widget-position-right;
        z-index: $line-messenger-widget-chat-widget-index;
    }

    .chat-widget {
        position: fixed;
        bottom: $line-messenger-widget-chat-widget-bottom;
        right: $line-messenger-widget-chat-widget-right;
        z-index: $line-messenger-widget-chat-widget-index;
        @include breakpoint(medium down) {
            bottom: $line-messenger-widget-chat-widget-bottom--medium-down;
            right: $line-messenger-widget-chat-widget-right--medium-down;
        }

        .chat-widget-image {
            height: $line-messenger-widget-chat-widget-height;
            @include breakpoint(large) {
                cursor: pointer;
            }

            &.chat-widget-image-desktop {
                display: none;
                @include breakpoint(large) {
                    display: block;
                }
            }

            &.chat-widget-image-mobile {
                @include breakpoint(large) {
                    display: none;
                }

                img {
                    width: 90px;
                    margin: 30px;
                }
            }
        }
    }

    .chat-widget-close,
    .chat-window-close {
        position: absolute;
        right: $line-messenger-widget-chat-close-right;
        font-size: $line-messenger-widget-chat-close-size;
        top: $line-messenger-widget-chat-close-top;
        font-weight: bold;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .chat-widget-close {
        @include breakpoint(large) {
            display: block;
            top: 5px;
            right: -5px;
        }
    }

    .chat-window {
        position: fixed;
        width: $line-messenger-widget-chat-window-width;
        bottom: 290px;
        right: 30px;
        box-shadow: #8c8c81 7px 7px 30px 1px;
        background-color: $line-messenger-widget-color-white;
        border-radius: 6px 6px 0 0;
        text-align: $line-messenger-widget-chat-window-text-align;

        .chat-window-header {
            height: 40px;
            display: flex;
            align-items: center;
            background: $line-messenger-widget-color-line-background;
            border-radius: 6px 6px 0 0;

            img {
                width: $line-messenger-widget-chat-window-image-width;
                height: $line-messenger-widget-chat-window-image-height;
                margin: $line-messenger-widget-chat-window-image-margin;
            }

            .icon-title {
                color: $line-messenger-widget-color-white;
                font-size: $line-messenger-widget-chat-icon-size;
            }

            .chat-window-close {
                top: $line-messenger-widget-chat-window-close-top;
                right: $line-messenger-widget-chat-window-close-right;
                font-weight: normal;
                color: $line-messenger-widget-color-white;
                font-size: $line-messenger-widget-chat-icon-size;
            }
        }

        .chat-window-body {
            padding: $line-messenger-widget-chat-window-body-padding;
            display: flex;
            flex-direction: column;
            align-items: $line-messenger-widget-chat-window-body-align-items;

            .desc {
                font-size: $line-messenger-widget-chat-desc-size;
                line-height: $line-messenger-widget-chat-desc-line-height;
                font-weight: bold;
            }

            .subtext {
                font-size: $line-messenger-widget-chat-descsub-size;
                line-height: $line-messenger-widget-chat-descsub-line-height;
                padding-top: 10px;
            }

            .chat-widget-qr-code {
                width: $line-messenger-widget-qr-code-width;
            }
        }
    }
}