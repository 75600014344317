$loyalty-membershipcard-font-size: rem-calc(14) !default;
$loyalty-membershipcard-font-size--large: rem-calc(12) !default;
$loyalty-membershipcard-info-wrapper-margin: rem-calc(0 0 0 10) !default;

@mixin apaclayer-components-loyalty-membershipcard {
    .c-loyalty-membershipcard__points-expiry,
    .c-loyalty-membershipcard__full-name,
    .c-loyalty-membershipcard__available-points,
    .c-loyalty-membershipcard__available-points-label,
    .c-loyalty-membershipcard__tier-info-name,
    .c-loyalty-membershipcard__tier-info-label {
        font-size: $loyalty-membershipcard-font-size;

        @include breakpoint(large) {
            font-size: $loyalty-membershipcard-font-size--large;
        }
    }

    .c-loyalty-membershipcard__info-wrapper {
        margin: $loyalty-membershipcard-info-wrapper-margin;
    }
}
