$loyalty-vouchertile-image-position: relative !default;
$loyalty-vouchertile-wrapper-position: relative !default;
$loyalty-vouchertile-image-after-border: 2px solid color(border) !default;
$loyalty-vouchertile-image-after-height: 100% !default;
$loyalty-vouchertile-image-after-position: absolute !default;
$loyalty-vouchertile-image-after-right: rem-calc(-15) !default;

$loyalty-vouchertile-image-max-height: rem-calc(120) !default;
$loyalty-vouchertile-promo-info-wrapper-width: 100% !default;
$loyalty-vouchertile-promo-info-wrapper-flex-direction: column !default;
$loyalty-vouchertile-promo-info-padding: rem-calc(4 5) !default;
$loyalty-vouchertile-promo-info-line-height: 1.1 !default;
$loyalty-vouchertile-apply-margin: rem-calc(16 0 0) !default;
$loyalty-vouchertile-promo-info-word-break: break-word !default;

$loyalty-vouchertile-warning-info-color: #fff !default;
$loyalty-vouchertile-warning-info-text-align: center !default;
$loyalty-vouchertile-disable-opacity: 0.7 !default;
$loyalty-vouchertile-warning-before-opacity: 0.75 !default;
$loyalty-vouchertile-warning-before-background-color: color(text) !default;

@mixin apaclayer-components-loyalty-vouchertile {
    .c-loyalty-vouchertile {
        &.m-voucher-disabled {
            opacity: $loyalty-vouchertile-disable-opacity;
        }

        &:hover {
            .c-loyalty-vouchertile__warning {
                visibility: visible;
            }
        }

        .c-loyalty-vouchertile__wrapper,
        .c-loyalty-vouchertile__image {
            position: $loyalty-vouchertile-image-position;

            @include breakpoint(large) {
                &::after {
                    border-right: $loyalty-vouchertile-image-after-border;
                    content: "";
                    height: $loyalty-vouchertile-image-after-height;
                    position: $loyalty-vouchertile-image-after-position;
                    top: 0;
                    right: $loyalty-vouchertile-image-after-right;
                }
            }

            &.m-secondary {
                &::after {
                    display: none;
                }
            }

            img {
                width: 100%;
                max-height: $loyalty-vouchertile-image-max-height;
            }
        }
    }

    .c-loyalty-vouchertile__promo-info-wrapper {
        flex-direction: $loyalty-vouchertile-promo-info-wrapper-flex-direction;
        position: absolute;
        top: 0;
        width: $loyalty-vouchertile-promo-info-wrapper-width;
        height: 100%;
    }

    .c-loyalty-vouchertile__promo-info {
        padding: $loyalty-vouchertile-promo-info-padding;
        line-height: $loyalty-vouchertile-promo-info-line-height;
        word-break: $loyalty-vouchertile-promo-info-word-break;
    }

    .c-loyalty-vouchertile__apply {
        margin: $loyalty-vouchertile-apply-margin;
    }

    .c-loyalty-vouchertile__warning {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;

        &::before {
            content: "";
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: $loyalty-vouchertile-warning-before-opacity;
            background-color: $loyalty-vouchertile-warning-before-background-color;
        }
    }

    .c-loyalty-vouchertile__warning-info {
        color: $loyalty-vouchertile-warning-info-color;
        position: relative;
        text-align: $loyalty-vouchertile-warning-info-text-align;
    }
}
