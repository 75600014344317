$third-party-fbchat-bottom: rem-calc(180) !default;
$third-party-fbchat-width: rem-calc(70) !default;
$third-party-fbchat-width--large: rem-calc(100) !default;
$third-party-fbchat-dialog-iframe-top: rem-calc(150) !default;
$third-party-fbchat-dialog-iframe-top--large: auto !default;
$third-party-fbchat-dialog-iframe-bottom--large: rem-calc(170) !default;
$third-party-fbchat-dialog-iframe-height: 50% !default;
$third-party-fbchat-dialog-iframe-height--large: rem-calc(550) !default;
$third-party-fbchat-dialog-icon-display: none !default;

@mixin apac-third-party-fb-chat {
    /* stylelint-disable*/
    .c-fbchat {
        position: fixed;
        bottom: $third-party-fbchat-bottom;
        width: $third-party-fbchat-width;
        right: 0;

        @include breakpoint(large) {
            width: $third-party-fbchat-width--large;
        }
    }

    .fb-customerchat {
        iframe {
            top: $third-party-fbchat-dialog-iframe-top !important;
            height: $third-party-fbchat-dialog-iframe-height !important;

            @include breakpoint(large) {
                bottom: $third-party-fbchat-dialog-iframe-bottom--large !important;
                height: $third-party-fbchat-dialog-iframe-height--large !important;
                top: $third-party-fbchat-dialog-iframe-top--large !important;
            }
        }
    }

    .fb_dialog {
        display: $third-party-fbchat-dialog-icon-display !important;
    }
}
