$loyalty-rewardsbuckets-padding: rem-calc(15) !default;
$loyalty-rewardsbuckets-padding--large: rem-calc(30 25) !default;
$loyalty-rewardsbuckets-tabs-padding: rem-calc(15) !default;
$loyalty-rewardsbuckets-tabs-padding--large: rem-calc(0 15) !default;
$loyalty-rewardsbuckets-tabs-margin: rem-calc(15 0 0) !default;
$loyalty-rewardsbuckets-tabs-margin--large: $loyalty-rewardsbuckets-tabs-margin;
// Gift Icon
$loyalty-rewardsbuckets-signin-gift-height: rem-calc(34) !default;
$loyalty-rewardsbuckets-signin-icon-name: gift !default;
$loyalty-rewardsbuckets-signin-icon-color: color(dark) !default;
$loyalty-rewardsbuckets-signin-icon-width: rem-calc(18) !default;
$loyalty-rewardsbuckets-signin-icon-height: rem-calc(18) !default;

$loyalty-rewardsbuckets-viewmore-margin: rem-calc(30 0 0) !default;
$loyalty-rewardsbuckets-viewmore-margin--large: rem-calc(50 0 0) !default;

@mixin apaclayer-components-loyalty-rewardsbuckets {
    .c-loyalty-rewardsbuckets {
        padding: $loyalty-rewardsbuckets-padding;

        @include breakpoint(large) {
            padding: $loyalty-rewardsbuckets-padding--large;
        }

        .c-loyalty-rewardsbuckets__tabs {
            padding: $loyalty-rewardsbuckets-tabs-padding;
            margin: $loyalty-rewardsbuckets-tabs-margin;
    
            @include breakpoint(large) {
                padding: $loyalty-rewardsbuckets-tabs-padding--large;
                margin: $loyalty-rewardsbuckets-tabs-margin--large;
            }
        }
    }

    .c-loyalty-rewardsbuckets__signin {
        .m-gift {
            height: $loyalty-rewardsbuckets-signin-gift-height;

            &::before {
                @include svg-icon($loyalty-rewardsbuckets-signin-icon-name, $loyalty-rewardsbuckets-signin-icon-color, 100%, center, no-repeat);

                content: '';
                display: inline-block;
                height: $loyalty-rewardsbuckets-signin-icon-height;
                width: $loyalty-rewardsbuckets-signin-icon-width;
            }
        }
    }

    .c-loyalty-rewardsbuckets__viewmore {
        margin: $loyalty-rewardsbuckets-viewmore-margin;

        @include breakpoint(large) {
            margin: $loyalty-rewardsbuckets-viewmore-margin--large;
        }
    }
}
