/// Global spacing for form elements.
/// @type Number

$form-radius: rem-calc(4) !default;
$form-spacing-bottom: rem-calc(15) !default;
$form-row-grouped-padding: rem-calc(10) !default;
$form-row-margin: 0 (-$form-row-grouped-padding) !default;
$form-field-margin: 0 0 $form-spacing-bottom !default;
$form-row-field-margin: 0 $form-row-grouped-padding $form-spacing-bottom !default;
$form-row-field-has-description-margin: 0 $form-row-grouped-padding rem-calc(3) !default;
$form-title-margin: rem-calc(0 0 15) !default;
$form-error-message-color: color(alert) !default;
$form-error-message-font-size: rem-calc(12) !default;
$form-error-message-margin: rem-calc(-11) 0 $form-spacing-bottom !default;
$form-error-message-text-transform: null !default;
$form-row-title-margin: rem-calc(0 0 15) !default;
$form-row-title-separator-margin: rem-calc(10 0 15) !default;
$form-row-title-font-weight: inherit !default;
$form-row-title-color: null !default;
$form-row-title-font-size: rem-calc(14) !default;
$form-row-title-text-transform: null !default;
$form-row-title-letter-spacing: null !default;
$form-row-legend-margin: rem-calc(0 0 15) !default;
$form-legend-font-size: rem-calc(12) !default;
$form-legend-color: color(text-secondary) !default;
$form-legend-margin: 0 0 $form-spacing-bottom !default;
$form-legend-letter-spacing: null !default;
$form-link-text-transform: lowercase !default;
$form-aside-width: rem-calc(230) !default;
$form-aside-margin: rem-calc(0 0 0 70) !default;
$form-legend-font-weight: null !default;

// Form modifications
$form-row-fullwidth-flex-wrap: wrap !default;
$form-row-fullwidth-flex-basis: 100% !default;
$form-field-fullwidth-flex-basis: 100% !default;

// Confirmation action buttons
$form-row-grouped-buttons-flex-direction: column !default;
$form-row-grouped-buttons-flex-direction--large: row !default;
$form-row-grouped-buttons-align-items: stretch !default;
$form-row-grouped-buttons-align-items--large: center !default;
$form-row-grouped-buttons-field-flex: 0 0 auto !default;
$form-row-grouped-buttons-field-flex-direction: column !default;
$form-row-grouped-buttons-field-align-items: center !default;
// Form field separator
$form-field-separator-border-bottom: 1px solid color(border) !default;
$form-field-separator-padding: rem-calc(0 0 15) !default;

@mixin lora-form {
    .c-form.m-relative-loader {
        position: relative;
    }

    .c-form__title {
        margin: $form-title-margin;
    }

    .c-form__row {
        align-items: flex-start;
        display: flex;
        flex-grow: 1;
        margin: $form-row-margin;
    }

    .c-form .c-field,
    .c-form .c-field + .c-field__error-message {
        flex-basis: 100%;
        margin: $form-field-margin;
    }

    .c-form__row .c-field,
    .c-form__row .c-field + .c-field__error-message {
        margin: $form-row-field-margin;
    }

    .c-form__row .c-field.m-has-description {
        margin: $form-row-field-has-description-margin;
    }

    .c-form__link {
        text-transform: $form-link-text-transform;
    }

    .c-form__submit {
        @include breakpoint(medium down) {
            @include lora-helper-button-expand;
        }
    }

    .c-form__row-title {
        font-size: $form-row-title-font-size;
        margin: $form-row-title-margin;
        font-weight: $form-row-title-font-weight;
        color: $form-row-title-color;
        text-transform: $form-row-title-text-transform;
        letter-spacing: $form-row-title-letter-spacing;

        &.m-separator {
            margin: $form-row-title-separator-margin;
        }
    }

    .c-form__row-legend {
        margin: $form-row-legend-margin;
    }

    .c-form__error-message:not(.c-alert) {
        color: $form-error-message-color;
        flex-basis: 100%;
        font-size: $form-error-message-font-size;
        margin: $form-error-message-margin;
        text-transform: $form-error-message-text-transform;
    }

    .c-form__legend {
        font-size: $form-legend-font-size;
        font-weight: $form-legend-font-weight;
        color: $form-legend-color;
        margin: $form-legend-margin;
        letter-spacing: $form-legend-letter-spacing;
    }

    .c-form__section {
        @include breakpoint(large) {
            flex-grow: 1;
        }
    }

    .c-form__aside {
        @include breakpoint(large) {
            flex-flow: column;
            margin: $form-aside-margin;
            width: $form-aside-width;
        }
    }

    // Form modifications
    .c-form.m-secondary {
        @include breakpoint(large) {
            align-items: flex-start;
            display: flex;
            flex-flow: row wrap;
            flex-grow: 1;
            justify-content: space-between;
        }
    }

    .c-form.m-reset {
        flex-direction: row;
        padding: rem-calc(0);

        .c-form__row,
        .c-field {
            margin: rem-calc(0);
            padding: rem-calc(0);
        }
    }

    // Form Row modifications
    .c-form__row.m-grouped {
        padding: 0 $form-row-grouped-padding;

        .c-field,
        .c-field__error-message {
            margin-left: 0;
            margin-right: 0;
        }

        // Reset border radius between grouped form fields for seamless stacking
        .c-field:not(:first-child) {
            #{text-inputs()},
            textarea {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        .c-field:not(:last-child) {
            #{text-inputs()},
            textarea {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    .c-form__row.m-inlined {
        flex-wrap: wrap;

        .c-field {
            flex-basis: auto;
        }
    }

    .c-form__row.m-inlined-auto {
        flex-wrap: wrap;

        .c-field {
            flex: 0 1 auto;
        }
    }

    .c-form__row.m-grouped-buttons {
        align-items: $form-row-grouped-buttons-align-items;
        flex-direction: $form-row-grouped-buttons-flex-direction;

        @include breakpoint(large) {
            align-items: $form-row-grouped-buttons-align-items--large;
            flex-direction: $form-row-grouped-buttons-flex-direction--large;
        }

        .c-field {
            align-items: $form-row-grouped-buttons-field-align-items;
            flex: $form-row-grouped-buttons-field-flex;
            flex-direction: $form-row-grouped-buttons-field-flex-direction;
        }
    }

    .c-form__row.m-plain {
        .c-field {
            @include breakpoint(large) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .c-form__row.m-fullwidth {
        flex-wrap: $form-row-fullwidth-flex-wrap;

        .c-form__row {
            flex-basis: $form-row-fullwidth-flex-basis;
        }

        .c-field {
            flex-basis: $form-field-fullwidth-flex-basis;
        }
    }

    .c-form__row.m-initial-fullwidth {
        flex-basis: 100%;
    }

    @each $breakpoint, $width in $breakpoints {
        @include breakpoint($breakpoint only) {
            .c-form__row.m-#{$breakpoint}-fullwidth {
                flex-wrap: $form-row-fullwidth-flex-wrap;

                .c-form__row {
                    flex-basis: $form-row-fullwidth-flex-basis;
                }

                .c-field {
                    flex-basis: $form-field-fullwidth-flex-basis;
                }
            }
        }

        @include breakpoint($breakpoint) {
            .c-form__row.m-#{$breakpoint}-nowrap {
                flex-wrap: nowrap;
            }
        }
    }

    // Form Field modifications
    .c-form .c-field.m-grow {
        flex-grow: 1;
    }

    .c-form .c-field.m-autosize {
        flex-basis: auto;
    }

    .c-form__row > .c-form__row {
        margin: 0;
    }

    .c-field.m-separator {
        border-bottom: $form-field-separator-border-bottom;
        padding: $form-field-separator-padding;
    }
}
