$loyalty-tier-summary-wrapper-align: center !default;
$loyalty-tier-summary-title-font: 700 #{rem-calc(24)} / 1.4 $font-primary !default;
$loyalty-tier-summary-title-font--large: 700 #{rem-calc(30)} / 1.4 $font-primary !default;
$loyalty-tier-summary-title-margin: rem-calc(0 0 5) !default;
$loyalty-tier-summary-subtitle-font: #{rem-calc(18)} / 1 $font-primary !default;
$loyalty-tier-summary-subtitle-font--large: $loyalty-tier-summary-subtitle-font !default;
$loyalty-tier-summary-subtitle-display: block !default;
$loyalty-tier-summary-font: 500 #{rem-calc(14)} $font-primary !default;
$loyalty-tier-summary-margin-bottom: rem-calc(30) !default;

@mixin apaclayer-components-loyalty-tier-summary {
    .c-loyalty-tier-summary__wrapper {
        text-align: center;
    }

    .c-loyalty-tier-summary__title {
        margin: $loyalty-tier-summary-title-margin;
        font: $loyalty-tier-summary-title-font;

        @include breakpoint(large) {
            font: $loyalty-tier-summary-title-font--large;
        }
    }

    .c-loyalty-tier-summary__subtitle {
        font: $loyalty-tier-summary-subtitle-font;
        margin-bottom: $loyalty-tier-summary-margin-bottom;
        display: $loyalty-tier-summary-subtitle-display;

        @include breakpoint(large) {
            font: $loyalty-tier-summary-subtitle-font--large;
        }
    }

    .c-loyalty-tier-summary {
        font: $loyalty-tier-summary-font;
        margin-bottom: $loyalty-tier-summary-margin-bottom;
    }

    .c-loyalty-tier-summary__item {
        font: $loyalty-tier-summary-font;

        .c-loyalty-tier-summary__text {
            font-weight: 700;
        }
    }
}