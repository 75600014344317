$loyalty-eloyalty-history-start-point-current-z-index: 4 !default;
$loyalty-eloyalty-history-bar-wrapper-background-color: color(border) !default;
$loyalty-eloyalty-history-bar-background-color: #000 !default;
$loyalty-eloyalty-history-bar-start-point-background-color: #000 !default;
$loyalty-eloyalty-history-bar-start-point-width: rem-calc(14) !default;
$loyalty-eloyalty-history-bar-start-point-height: rem-calc(14) !default;
$loyalty-eloyalty-history-bar-start-point-border-radius: rem-calc(20) !default;
$loyalty-eloyalty-history-bar-start-point-border: rem-calc(3) solid color(primary-active) !default;
$loyalty-eloyalty-history-bar-start-point-locked-background-color: color(light) !default;
$loyalty-eloyalty-history-bar-start-point-locked-border: rem-calc(3) solid color(border) !default;
$loyalty-eloyalty-history-progress-point-width: rem-calc(28) !default;
$loyalty-eloyalty-history-progress-point-height: $loyalty-eloyalty-history-progress-point-width !default;
$loyalty-eloyalty-history-tier-tooltip-z-index: 3 !default;
$loyalty-eloyalty-history-tier-tooltip-offset--large: rem-calc(115) !default;
$loyalty-eloyalty-history-tier-tooltip-padding: rem-calc(20) !default;
$loyalty-eloyalty-history-tier-tooltip-background: color(light) !default;
$loyalty-eloyalty-history-tier-tooltip-height: rem-calc(95) !default;
$loyalty-eloyalty-history-tier-tooltip-width: rem-calc(300) !default;
$loyalty-eloyalty-history-tier-tooltip-width--large: rem-calc(450) !default;
$loyalty-eloyalty-history-tier-tooltip-last-left--large: rem-calc($loyalty-eloyalty-history-tier-tooltip-width--large - $loyalty-eloyalty-history-tier-tooltip-offset--large) !default;
$loyalty-eloyalty-history-tier-title-font: 700 #{rem-calc(14)} / 1.45 $font-primary !default;
$loyalty-eloyalty-history-tier-subtitle-font: 700 #{rem-calc(12)} / 1.45 $font-primary !default;
$loyalty-eloyalty-history-tier-title-text-align: center !default;
$loyalty-eloyalty-history-tier-subtitle-color: color(text-secondary) !default;
$loyalty-eloyalty-history-tier-column-text-align: center !default;
$loyalty-eloyalty-history-tier-card-title-font: 700 #{rem-calc(14)} / 1.45 $font-primary !default;
$loyalty-eloyalty-history-tier-card-subtitle-font: 500 #{rem-calc(12)} / 1.45 $font-primary !default;
$loyalty-eloyalty-history-tier-card-subtitle-color: color(text-secondary) !default;
$loyalty-eloyalty-history-tier-card-border: 1px solid color(border) !default;
$loyalty-eloyalty-history-tier-card-details-padding: rem-calc(15) !default;
$loyalty-eloyalty-history-tier-card-benefits-icon: "chevron-down" !default;
$loyalty-eloyalty-history-tier-card-benefits-icon-color: color(dark) !default;
$loyalty-eloyalty-history-tier-card-benefits-font: 700 #{rem-calc(12)} / 1.45 $font-primary !default;
$loyalty-eloyalty-history-tier-card-benefits-margin: rem-calc(10) 0 0 0 !default;
$loyalty-eloyalty-history-bar-wrapper-margin-bottom: $loyalty-eloyalty-history-tier-tooltip-height + rem-calc(30) !default;
$loyalty-eloyalty-history-bar-wrapper-empty-margin-bottom: rem-calc(30) !default;
$loyalty-eloyalty-history-tier-card-benefits-content-list-item-padding: rem-calc(10 0 0 20) !default;
$loyalty-eloyalty-history-tier-card-benefits-list-item-icon: "tick" !default;
$loyalty-eloyalty-history-tier-card-benefits-list-item-icon-color: color(dark) !default;
$loyalty-eloyalty-history-tier-card-benefits-content-dob-qualify-margin: rem-calc(20 0 0) !default;
$loyalty-eloyalty-history-tier-card-benefits-content-list-item-icon-width: rem-calc(15) !default;
$loyalty-eloyalty-history-tier-card-benefits-content-list-item-icon-height: rem-calc(9) !default;
$loyalty-eloyalty-history-tier-card-benefits-content-list-item-icon-margin: rem-calc(5 0 0) !default;
$loyalty-eloyalty-history-title-font: #{rem-calc(23)} / 1.4 $font-primary !default;

// progress bar
$loyalty-eloyalty-history-bar-z-index: 1 !default;
$loyalty-eloyalty-history-bar-bottom: rem-calc(-24) !default;
$loyalty-eloyalty-history-bar-first-left: rem-calc(10) !default;
$loyalty-eloyalty-history-bar-first-left--large: rem-calc(15) !default;
$loyalty-eloyalty-history-bar-last-left: rem-calc(-10) !default;
$loyalty-eloyalty-history-bar-last-left--large: rem-calc(-15) !default;
$loyalty-eloyalty-history-bar-current-height: rem-calc(5) !default;
$loyalty-eloyalty-history-bar-locked-height: 0 !default;

//images
$loyalty-eloyalty-history-item-offset: 0 !default; // Offset can be used for a proper tooltp vertical positioning
$loyalty-eloyalty-history-item-padding: rem-calc(0 0 15) !default;

$loyalty-eloyalty-history-item-inactive-opacity: 0.5 !default;
$loyalty-eloyalty-history-item-image-margin: rem-calc(0 0 10) !default;

$loyalty-eloyalty-history-progress-point-none: none;
$loyalty-eloyalty-history-progress-point-background-active: #000 !default;
$loyalty-eloyalty-history-progress-point-border-color: #000 !default;
$loyalty-eloyalty-history-progress-point-not-locked-border-color: color(border) !default;

$loyalty-eloyalty-history-start-point-locked-background-color: color(light) !default;
$loyalty-eloyalty-history-progress-point-background-transparent: transparent;

$loyalty-eloyalty-history-items-column-max-width: rem-calc(125) !default;
$loyalty-eloyalty-history-items-column-max-width--medium: rem-calc(150) !default;
$loyalty-eloyalty-history-items-column-max-width--large: 100% !default;
$loyalty-eloyalty-history-tiers-info-section-background-color: color(secondary) !default;
$loyalty-eloyalty-history-tiers-info-section-padding: rem-calc(15) !default;
$loyalty-eloyalty-history-tiers-info-section-margin: rem-calc(30 0) !default;
$loyalty-eloyalty-history-tiers-info-item-title-margin: rem-calc(10 0) !default;

$loyalty-eloyalty-history-progress-bar-padding: 0 !default;
$loyalty-eloyalty-history-progress-bar-margin: rem-calc(10 0 10 -3) !default;
$loyalty-eloyalty-history-progress-bar-border-radius: 0 !default;
$loyalty-eloyalty-history-progress-bar-border-right-width: 0 !default;
$loyalty-eloyalty-history-progress-bar-border-left-width: 0 !default;

$loyalty-eloyalty-history-progress-bar-first-child-border-top-left-radius: rem-calc(8) !default;
$loyalty-eloyalty-history-progress-bar-first-child-border-bottom-left-radius: rem-calc(8) !default;
$loyalty-eloyalty-history-progress-bar-first-child-border-right-width: rem-calc(1) !default;
$loyalty-eloyalty-history-progress-bar-first-child-margin-left: rem-calc(3) !default;

$loyalty-eloyalty-history-progress-bar-last-child-border-top-right-radius: rem-calc(8) !default;
$loyalty-eloyalty-history-progress-bar-last-child-border-bottom-right-radius: rem-calc(8) !default;
$loyalty-eloyalty-history-progress-bar-last-child-border-right-width: rem-calc(1) !default;

$loyalty-eloyalty-history-progress-bar-line-width: 100% !default;
$loyalty-eloyalty-history-progress-bar-line-display: flex !default;

$loyalty-eloyalty-history-item-image-opacity: 0.5 !default;
$loyalty-eloyalty-history-item-image-opacity-hover: 1 !default;

@mixin apaclayer-components-eloyalty-history {
    .c-loyalty-eloyalty-history__tier-bar-wrapper {
        display: grid;
        overflow-x: scroll;

        @include breakpoint(large) {
            overflow-x: initial;
        }

        .m-locked:not(.c-loyalty-eloyalty-history__bar-start-point) {
            opacity: 1;
        }
    }

    .c-loyalty-eloyalty-history__tier-cards {
        .m-locked:not(.m-opened) {
            opacity: 0.5;
        }
    }

    .c-loyalty-eloyalty-history__tier-title {
        font: $loyalty-eloyalty-history-tier-title-font;
    }

    .c-loyalty-eloyalty-history__tier-subtitle {
        font: $loyalty-eloyalty-history-tier-subtitle-font;
        color: $loyalty-eloyalty-history-tier-subtitle-color;
    }

    .c-loyalty-eloyalty-history__bar-wrapper {
        @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: margin);

        display: flex;
        justify-content: space-around;
        align-items: center;
        height: rem-calc(3);
        background-color: $loyalty-eloyalty-history-bar-wrapper-background-color;
        position: relative;
        margin-bottom: $loyalty-eloyalty-history-bar-wrapper-margin-bottom;
    }

    .c-loyalty-eloyalty-history__bar {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            background-color: $loyalty-eloyalty-history-bar-background-color;
            left: 0;
            bottom: $loyalty-eloyalty-history-bar-bottom;
            z-index: $loyalty-eloyalty-history-bar-z-index;
        }

        &.m-first {
            &::before {
                left: $loyalty-eloyalty-history-bar-first-left;
                width: 100%;

                @include breakpoint(large) {
                    left: $loyalty-eloyalty-history-bar-first-left--large;
                    width: calc(100% - 15px);
                }
            }
        }

        &.m-last {
            &::before {
                left: $loyalty-eloyalty-history-bar-last-left;

                @include breakpoint(large) {
                    left: $loyalty-eloyalty-history-bar-last-left--large;
                }
            }
        }

        &.m-locked {
            &::before {
                height: $loyalty-eloyalty-history-bar-current-height;
            }
        }

        &.m-current {
            &::before {
                height: $loyalty-eloyalty-history-bar-current-height;
            }

            ~ .m-locked {
                &::before {
                    height: $loyalty-eloyalty-history-bar-locked-height;
                }
            }
        }
    }

    .c-loyalty-eloyalty-history__bar-start-point {
        background-color: $loyalty-eloyalty-history-bar-start-point-background-color;
        width: $loyalty-eloyalty-history-bar-start-point-width;
        height: $loyalty-eloyalty-history-bar-start-point-height;
        border-radius: $loyalty-eloyalty-history-bar-start-point-border-radius;
        border: $loyalty-eloyalty-history-bar-start-point-border;
        position: relative;

        &.m-current ~ .m-locked {
            background-color: $loyalty-eloyalty-history-bar-start-point-locked-background-color;
            border: $loyalty-eloyalty-history-bar-start-point-locked-border;
        }

        &.m-current {
            &::after {
                content: '';
                height: rem-calc(20);
                width: rem-calc(20);
                background: color(light);
                box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
                position: absolute;
                transform: rotate(45deg);
                left: rem-calc(-5);
                top: rem-calc(16);
                z-index: $loyalty-eloyalty-history-start-point-current-z-index;
            }
        }
    }

    .c-loyalty-eloyalty-history__bar-wrapper.m-empty {
        margin-bottom: $loyalty-eloyalty-history-bar-wrapper-empty-margin-bottom;

        .m-current {
            &::after {
                display: none;
            }
        }
    }

    .c-loyalty-eloyalty-history__tier-columns {
        display: flex;
        margin: 0 0 rem-calc(20);
    }

    .c-loyalty-eloyalty-history__tier-column {
        @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: padding);

        width: 90vw;
        text-align: $loyalty-eloyalty-history-tier-column-text-align;

        @include breakpoint(large) {
            width: auto;
        }
    }

    .c-loyalty-eloyalty-history__tier-tooltip {
        display: flex;
        align-items: center;
        position: absolute;
        width: $loyalty-eloyalty-history-tier-tooltip-width;
        max-width: 90vw;
        height: $loyalty-eloyalty-history-tier-tooltip-height;
        background: $loyalty-eloyalty-history-tier-tooltip-background;
        padding: $loyalty-eloyalty-history-tier-tooltip-padding;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: $loyalty-eloyalty-history-tier-tooltip-z-index;
        left: -$loyalty-eloyalty-history-tier-tooltip-width / 2;
        top: rem-calc(23);

        @include breakpoint(large) {
            width: $loyalty-eloyalty-history-tier-tooltip-width--large;
            left: -$loyalty-eloyalty-history-tier-tooltip-width--large / 2;
        }

        &.m-first {
            @include breakpoint(large) {
                left: (-$loyalty-eloyalty-history-tier-tooltip-width--large / 2 + $loyalty-eloyalty-history-tier-tooltip-offset--large);
            }
        }

        &.m-last {
            @include breakpoint(large) {
                left: initial;
                right: (-$loyalty-eloyalty-history-tier-tooltip-width--large / 2 + $loyalty-eloyalty-history-tier-tooltip-offset--large);
            }
        }
    }

    .c-loyalty-eloyalty-history__tier-card {
        border: $loyalty-eloyalty-history-tier-card-border;
    }

    .c-loyalty-eloyalty-history__tier-card-details {
        padding: $loyalty-eloyalty-history-tier-card-details-padding;
    }

    .c-loyalty-eloyalty-history__tier-card-title {
        font: $loyalty-eloyalty-history-tier-card-title-font;
    }

    .c-loyalty-eloyalty-history__tier-card-subtitle {
        font: $loyalty-eloyalty-history-tier-card-subtitle-font;
        color: $loyalty-eloyalty-history-tier-card-subtitle-color;
    }

    .c-loyalty-eloyalty-history__tier-card-benefits {
        text-align: right;
        margin: $loyalty-eloyalty-history-tier-card-benefits-margin;

        &.m-opened {
            .c-loyalty-eloyalty-history__tier-card-benefits-content {
                display: block;
            }
        }
    }

    .c-loyalty-eloyalty-history__tier-card-benefits-title {
        font: $loyalty-eloyalty-history-tier-card-benefits-font;
        cursor: pointer;

        &::after {
            content: '';
            display: inline-block;
            width: rem-calc(15);
            height: rem-calc(9);
            margin: rem-calc(0 0 0 10);

            @include svg-icon($loyalty-eloyalty-history-tier-card-benefits-icon, $loyalty-eloyalty-history-tier-card-benefits-icon-color, 100%, center, no-repeat);

            .m-opened & {
                transform: rotate(-180deg);
            }
        }
    }

    .c-loyalty-eloyalty-history__tier-card-benefits-content {
        display: none;
    }

    .c-loyalty-eloyalty-history__tier-card-benefits-content-list-item {
        padding: $loyalty-eloyalty-history-tier-card-benefits-content-list-item-padding;
        position: relative;

        &.m-tick {
            &::before {
                content: '';
                display: inline-block;
                width: $loyalty-eloyalty-history-tier-card-benefits-content-list-item-icon-width;
                height: $loyalty-eloyalty-history-tier-card-benefits-content-list-item-icon-height;
                margin: $loyalty-eloyalty-history-tier-card-benefits-content-list-item-icon-margin;
                position: absolute;
                left: 0;

                @include svg-icon($loyalty-eloyalty-history-tier-card-benefits-list-item-icon, $loyalty-eloyalty-history-tier-card-benefits-list-item-icon-color, 100%, left center, no-repeat);
            }
        }
    }

    .c-loyalty-eloyalty-history__tier-card-benefits-content-dob-qualify {
        margin: $loyalty-eloyalty-history-tier-card-benefits-content-dob-qualify-margin;
    }

    .c-account__subtitle {
        font: $loyalty-eloyalty-history-title-font;
    }

    .c-loyalty-tiers {
        display: table;

        @include breakpoint(medium) {
            margin: 0 auto;
        }
    }

    .c-loyalty-tiers__items {
        width: max-content;

        @include breakpoint(large) {
            width: auto;
        }

        .c-loyalty-tiers__items-column {
            max-width: $loyalty-eloyalty-history-items-column-max-width;
            z-index: 0;

            @include breakpoint(medium) {
                max-width: $loyalty-eloyalty-history-items-column-max-width--medium;
            }

            @include breakpoint(large) {
                max-width: $loyalty-eloyalty-history-items-column-max-width--large;
            }
        }
    }

    // incepe de aici
    .c-loyalty-tiers__item {
        padding: $loyalty-eloyalty-history-item-padding;
        margin-bottom: $loyalty-eloyalty-history-item-offset;
        position: relative;
        z-index: 1; // stylelint-disable-line
    }

    .c-loyalty-tiers__item-image {
        margin: $loyalty-eloyalty-history-item-image-margin;
        cursor: pointer;
        position: relative;

        img {
            opacity: $loyalty-eloyalty-history-item-image-opacity;
        }

        &:hover,
        &:focus,
        &.m-active {
            img {
                opacity: $loyalty-eloyalty-history-item-image-opacity-hover;
            }
        }
    }

    .c-progress-bar__line {
        display: $loyalty-eloyalty-history-progress-bar-line-display;
        width: $loyalty-eloyalty-history-progress-bar-line-width;
    }

    .c-progress-bar {
        padding: $loyalty-eloyalty-history-progress-bar-padding;
        margin: $loyalty-eloyalty-history-progress-bar-margin;
        border-radius: $loyalty-eloyalty-history-progress-bar-border-radius;
        border-right-width: $loyalty-eloyalty-history-progress-bar-border-right-width;
        border-left-width: $loyalty-eloyalty-history-progress-bar-border-left-width;
            
        
        &:first-child {
            border-top-left-radius: $loyalty-eloyalty-history-progress-bar-first-child-border-top-left-radius;
            border-bottom-left-radius: $loyalty-eloyalty-history-progress-bar-first-child-border-bottom-left-radius;
            border-right-width: $loyalty-eloyalty-history-progress-bar-first-child-border-right-width;
            margin-left: $loyalty-eloyalty-history-progress-bar-first-child-margin-left;
        }

        &:last-child {
            border-top-right-radius: $loyalty-eloyalty-history-progress-bar-last-child-border-top-right-radius;
            border-bottom-right-radius: $loyalty-eloyalty-history-progress-bar-last-child-border-bottom-right-radius;
            border-right-width: $loyalty-eloyalty-history-progress-bar-last-child-border-right-width;
        }
    }

    .l-column-progress-bar {
        .c-progress-bar__point {
            cursor: pointer;
            background-image: $loyalty-eloyalty-history-progress-point-none;
            background-color: $loyalty-eloyalty-history-start-point-locked-background-color;
            width: $loyalty-eloyalty-history-progress-point-width;
            height: $loyalty-eloyalty-history-progress-point-height;

            &::before {
                background-color: $loyalty-eloyalty-history-progress-point-background-transparent;
            }

            &::after {
                background-color: $loyalty-eloyalty-history-progress-point-background-transparent;
            }
        }

        &.m-current,
        &.m-locked {
            .c-progress-bar__point {
                background-color: $loyalty-eloyalty-history-progress-point-background-active;
                border-color: $loyalty-eloyalty-history-progress-point-border-color;
            }
        }

        &.m-current ~ .m-locked {
            .c-progress-bar__point {
                background-color: $loyalty-eloyalty-history-start-point-locked-background-color;
                border-color: $loyalty-eloyalty-history-progress-point-not-locked-border-color;
            }
        }
    }

    .c-loyalty-eloyalty-tiers__info-section {
        background-color: $loyalty-eloyalty-history-tiers-info-section-background-color;
        padding: $loyalty-eloyalty-history-tiers-info-section-padding;
        margin: $loyalty-eloyalty-history-tiers-info-section-margin;

        .c-loyalty-tiers__info-item-title {
            margin: $loyalty-eloyalty-history-tiers-info-item-title-margin;
        }
    }
}
