$loyalty-paywithpoints-error-message-position: rem-calc(-15);

@mixin apaclayer-components-paywithpoints {
    .c-paywithpoints {
        .m-link-decoration {
            text-decoration: none;
        }

        .m-grouped-paywithpoints ~ .c-field__error-message.m-group {
            position: relative;
            top: $loyalty-paywithpoints-error-message-position;
        }
    }
}
