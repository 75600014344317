$loyalty-status-tools-display: block !default;
$loyalty-status-tools-display--large: flex !default;
$loyalty-status-tools-padding: rem-calc(15 10) !default;
$loyalty-status-tools-padding--large: rem-calc(15 20) !default;
$loyalty-status-tools-font: inherit !default;
$loyalty-status-tools-font--large: $loyalty-status-tools-font !default;
$loyalty-status-tools-justify-content--large: space-between !default;
$loyalty-status-tools-font-weight: 400 !default;
$loyalty-status-tools-width--medium-down: rem-calc(320) !default;
$loyalty-status-tools-margin--medium-down: 0 auto !default;
$loyalty-status-tools-text-align--medium-down: center !default;
$loyalty-status-collapsed-cursor: pointer !default;
$loyalty-status-cta-display: inline-block !default;
$loyalty-status-cta-position: relative !default;
$loyalty-status-cta-margin--medium-down: rem-calc(15 0 0) !default;
$loyalty-status-cta-icon: "chevron-down" !default;
$loyalty-status-cta-title-padding: rem-calc(0 25 0 0) !default;
$loyalty-status-cta-cursor: pointer !default;
$loyalty-status-cta-icon-color: color(dark) !default;
$loyalty-status-cta-icon-size: rem-calc(12) !default;
$loyalty-status-cta-icon-transition: transform 0.3s !default;
$loyalty-status-columns: 2 !default;
$loyalty-status-columns--large: 4 !default;
$loyalty-status-divider-enabled: false !default;
$loyalty-status-margin: rem-calc(0 0 20) !default;
$loyalty-status-margin--large: rem-calc(0 0 30) !default;
$loyalty-status-border: 1px solid color(border) !default;
$loyalty-status-margin--large: rem-calc(0 0 30) !default;
$loyalty-status-description-margin: rem-calc(0 0 0 15) !default;
$loyalty-status-tabs-padding: rem-calc(3 10 15) !default;
$loyalty-status-tabs-padding--large: rem-calc(3 70 15) !default;
$loyalty-status-tabs-margin: rem-calc(10 0 0) !default;
$loyalty-status-tabs-margin--large: rem-calc(30 0 0) !default;
$loyalty-status-description-display: block !default;
$loyalty-status-description-display--large: inline-block !default;

@mixin apaclayer-components-loyalty-status {
    .c-cart-loyalty {
        margin: $loyalty-status-margin;
        border: $loyalty-status-border;

        @include breakpoint(large) {
            margin: $loyalty-status-margin--large;
        }

        .c-product-tile__actions {
            @include breakpoint(large) {
                display: block;
            }
        }
    }

    .c-cart-loyalty__tools {
        display: $loyalty-status-tools-display;
        padding: $loyalty-status-tools-padding;
        font: $loyalty-status-tools-font;
        font-weight: $loyalty-status-tools-font-weight;
        cursor: pointer;

        @include breakpoint(medium down) {
            width: $loyalty-status-tools-width--medium-down;
            margin: $loyalty-status-tools-margin--medium-down;
            text-align: $loyalty-status-tools-text-align--medium-down;
        }

        @include breakpoint(large) {
            display: $loyalty-status-tools-display--large;
            justify-content: $loyalty-status-tools-justify-content--large;
            font: $loyalty-status-tools-font--large;
            padding: $loyalty-status-tools-padding--large;
        }
    }

     
    .c-cart-loyalty.m-collapsed {
        cursor: $loyalty-status-collapsed-cursor;

        .c-cart-loyalty__container {
            display: none;
        }

        .c-cart-loyalty__toggle-cta {
            &::after {
                transform: rotateZ(0deg);
            }
        }
    }

    .c-cart-loyalty__toggle-cta {
        display: $loyalty-status-cta-display;
        position: $loyalty-status-cta-position;
        padding: $loyalty-status-cta-title-padding;
        cursor: $loyalty-status-cta-cursor;

        @include breakpoint(medium down) {
            margin: $loyalty-status-cta-margin--medium-down;
        }

        &::after {
            @include svg-icon($loyalty-status-cta-icon, $loyalty-status-cta-icon-color, 100%, left, no-repeat);

            content: '';
            position: absolute;
            #{$global-right}: 0;
            top: 50%;
            margin-top: -($loyalty-status-cta-icon-size/2);
            transform: rotateZ(180deg);
            transition: $loyalty-status-cta-icon-transition;
            width: $loyalty-status-cta-icon-size;
            height: $loyalty-status-cta-icon-size;
        }
    }

    .c-cart-loyalty__description {
        margin: $loyalty-status-description-margin;
        display: $loyalty-status-description-display;

        @include breakpoint(large) {
            display: $loyalty-status-description-display--large;
        }
    }

    .c-cart-loyalty-rewards__tabs {
        margin: $loyalty-status-tabs-margin;
        padding: $loyalty-status-tabs-padding;

        @include breakpoint(large) {
            margin: $loyalty-status-tabs-margin--large;
            padding: $loyalty-status-tabs-padding--large;
        }
    }
}
