@import "../accordion";

$header-navigation-flex: 1 0 0% !default;
$header-navigation-flex--large: none !default;
// Items lists
$header-navigation-list-margin: 0 !default;
$header-navigation-list-level-1-background: color(light) !default;
$header-navigation-list-level-1-justify-content--large: space-around !default;
$header-navigation-list-level-2-padding--large: rem-calc(15 0) !default;
$header-navigation-list-level-2-margin--large: rem-calc(0 -10) !default;
// Items
$header-navigation-item-border-bottom: $global-border !default;
$header-navigation-item-first-border-top: $global-border !default;
$header-navigation-item-title-level-1-padding: rem-calc(10 0 10 30) !default;
$header-navigation-item-level-1-margin--large: 0 !default;
$header-navigation-item-level-1-white-space--large: normal !default;
$header-navigation-item-level-1-flex-grow--large: null !default;
$header-navigation-item-level-2-padding--large: rem-calc(5 10 0) !default;
$header-navigation-item-level-2-columns--large: 4 !default;
$header-navigation-item-level-2-width--large: (100% / $header-navigation-item-level-2-columns--large) !default;
$header-navigation-item-level-2-view-all-border: null !default;
$header-navigation-item-level-2-view-all-border-width: null !default;
$header-navigation-item-level-3-padding--large: rem-calc(5 0) !default;
$header-navigation-item-level-3-white-space--large: normal !default;
$header-navigation-item-level-3-all-link-font: #{rem-calc(12)} / 1.6 $font-primary !default;
$header-navigation-item-level-3-all-link-font--large: null !default;
$header-navigation-item-level-3-all-link-text-decoration: none !default;
$header-navigation-item-level-3-all-link-text-decoration--large: null !default;
$header-navigation-item-level-3-all-link-text-transform: none !default;
$header-navigation-item-level-3-all-link-text-transform--large: null !default;
$header-navigation-item-level-3-all-link-color: null !default;
$header-navigation-item-level-3-all-link-color--large: null !default;
$header-navigation-item-level-3-all-link-hover-color: null !default;
$header-navigation-item-level-3-all-link-margin--large: null !default;
$header-navigation-list-level-3-margin--large: 0 !default;
$header-navigation-item-level-3-border: null !default;
$header-navigation-item-level-3-border-width: null !default;
$header-navigation-item-level-3-first-border: null !default;
$header-navigation-item-level-3-first-border-width: null !default;
$header-navigation-item-level-3-column-gap: rem-calc(10) !default;
// Item titles
$header-navigation-item-title-padding: rem-calc(10 0) !default;
$header-navigation-item-title-level-1-font-size: rem-calc(14) !default;
$header-navigation-item-title-level-1-font-size--large: null !default;
$header-navigation-item-title-level-2-font-size: rem-calc(12) !default;
$header-navigation-item-title-level-2-font-size--large: null !default;
$header-navigation-item-title-level-3-font-size: rem-calc(12) !default;
$header-navigation-item-title-level-3-font-size--large: null !default;
$header-navigation-item-title-level-1-color: color(text) !default;
$header-navigation-item-title-level-2-color: color(text) !default;
$header-navigation-item-title-level-3-color: color(text) !default;
$header-navigation-item-title-level-1-color--large: null !default;
$header-navigation-item-title-level-2-color--large: null !default;
$header-navigation-item-title-level-3-color--large: null !default;
$header-navigation-item-title-level-3-line-height--large: null !default;
$header-navigation-item-title-level-1-padding-left: rem-calc(30) !default;
$header-navigation-item-title-level-2-padding-left: rem-calc(60) !default;
$header-navigation-item-title-level-1-expanded-background: color(primary-active) !default;
$header-navigation-item-title-level-1-expanded-color: color(light) !default;
$header-navigation-item-title-level-1-expanded-font: null !default;
$header-navigation-item-title-level-2-expanded-background: color(primary-active) !default;
$header-navigation-item-title-level-2-expanded-border-bottom: null !default;
$header-navigation-item-title-level-2-expanded-color: color(light) !default;
$header-navigation-item-title-level-2-background: color(element-background) !default;
$header-navigation-item-title-level-2-font-weight: normal !default;
$header-navigation-item-title-level-2-font-weight--large: bold !default;
$header-navigation-item-title-level-2-align-items--large: flex-start !default;
$header-navigation-item-title-level-3-background: color(global-background) !default;
$header-navigation-item-title-accordion-text-transform: none !default;
$header-navigation-item-title-accordion-icon-color: null !default;

$header-navigation-item-button-level-1-focus-icon: "chevron-down" !default;
$header-navigation-item-button-level-1-focus-icon-color: color(primary) !default;
$header-navigation-item-button-level-1-focus-icon-height: rem-calc(14) !default;
$header-navigation-item-button-level-1-focus-icon-width: rem-calc(8) !default;
$header-navigation-item-button-level-1-focus-icon-bottom: rem-calc(3) !default;
$header-navigation-item-button-level-1-focus-icon-usesvg: true !default;
$header-navigation-item-button-level-1-expanded-focus-icon: "chevron-top" !default;
$header-navigation-item-button-level-1-expanded-focus-icon-usesvg: true !default;
// Links
$header-navigation-link-color: null !default;
$header-navigation-link-font-weight: null !default;
$header-navigation-link-hover-font-weight: bold !default;
$header-navigation-link-font-size: null !default;
$header-navigation-link-letter-spacing: null !default;
$header-navigation-link-letter-spacing--large: null !default;
$header-navigation-link-margin: 0 !default;
$header-navigation-link-text-decoration: none !default;
$header-navigation-link-text-transform: none !default;
$header-navigation-link-level-1-color: inherit !default;
$header-navigation-link-level-1-color--large: null !default;
$header-navigation-link-level-1-hover-color: null !default;
$header-navigation-link-level-1-hover-color--large: null !default;
$header-navigation-link-level-1-hover-font-weight: null !default;
$header-navigation-link-level-1-font-family: null !default;
$header-navigation-link-level-1-font-family--large: null !default;
$header-navigation-link-level-1-font-weight: null !default;
$header-navigation-link-level-1-font-weight--large: null !default;
$header-navigation-link-level-1-font-size: null !default;
$header-navigation-link-level-1-font-size--large: null !default;
$header-navigation-link-level-1-text-transform: null !default;
$header-navigation-link-level-1-text-transform--large: null !default;
$header-navigation-link-level-1-text-align--large: null !default;
$header-navigation-link-level-1-letter-spacing--large: null !default;
$header-navigation-link-level-1-padding--large: rem-calc(14 16) !default;
$header-navigation-link-level-2-color: inherit !default;
$header-navigation-link-level-2-color--large: null !default;
$header-navigation-link-level-2-hover-color: null !default;
$header-navigation-link-level-2-font-weight: null !default;
$header-navigation-link-level-2-font-weight--large: null !default;
$header-navigation-link-level-2-font-size: null !default;
$header-navigation-link-level-2-font-size--large: null !default;
$header-navigation-link-level-2-text-transform--large: null !default;
$header-navigation-link-level-2-font-family: null !default;
$header-navigation-link-level-2-font-family--large: null !default;
$header-navigation-link-level-2-text-transform: null !default;
$header-navigation-link-level-2-hover-font-weight: null !default;
$header-navigation-link-level-2-letter-spacing--large: null !default;
$header-navigation-link-level-3-color: null !default;
$header-navigation-link-level-3-color--large: null !default;
$header-navigation-link-level-3-hover-color: null !default;
$header-navigation-link-level-3-font-weight: null !default;
$header-navigation-link-level-3-font-weight--large: null !default;
$header-navigation-link-level-3-text-transform: null !default;
$header-navigation-link-level-3-font-size: null !default;
$header-navigation-link-level-3-font-size--large: null !default;
$header-navigation-link-level-3-hover-font-weight: bold !default;
$header-navigation-link-level-3-padding--medium-down: rem-calc(10 0 10 90) !default;
$header-navigation-link-level-3-text-transform--large: null !default;

// Flyout
$header-navigation-flyout-element-background--large: color(global-background) !default;
$header-navigation-flyout-element-border-bottom--large: 1px solid color(border) !default;
$header-navigation-flyout-element-border-top--large: 1px solid color(border) !default;
$header-navigation-flyout-element-margin-left--large: -5px !default;
$header-navigation-flyout-element-box-shadow--large: none !default;
$header-navigation-flyout-element-before-background--large: color(overlay) !default;
$header-navigation-flyout-slot-padding--large: rem-calc(0) !default;

// Content tile
$header-navigation-content-color--large: color(text) !default;
$header-navigation-content-hover-color--large: $header-navigation-content-color--large !default;
$header-navigation-content-font--large: bold #{rem-calc(14)} / 1.5 $font-primary !default;
$header-navigation-content-hover-font--large: $header-navigation-content-font--large !default;
$header-navigation-content-margin--large: rem-calc(0 35 0 0) !default;
$header-navigation-content-text-decoration--large: none !default;
$header-navigation-content-title-color--large: null !default;
$header-navigation-content-title-font--large: null !default;
$header-navigation-content-title-margin--large: rem-calc(20 0 0) !default;
$header-navigation-content-title-text-transform--large: uppercase !default;

// Locale selector
$header-navigation-locale-content-padding: rem-calc(12 30) !default;
$header-navigation-locale-content-border-top: 1px solid color(border) !default;

// Vertical mode for desktop 2-level Navigation
$header-navigation-vertical-navigation-list-level-2-align-content--large: flex-start !default;
$header-navigation-vertical-navigation-list-level-2-flex-wrap--large: wrap !default;
$header-navigation-vertical-navigation-list-level-2-flex-flow--large: column $header-navigation-vertical-navigation-list-level-2-flex-wrap--large !default;
$header-navigation-vertical-navigation-list-level-2-max-height--large: rem-calc(390) !default;
$header-navigation-vertical-navigation-item-level-2-padding--large: rem-calc(5 0) !default;
$header-navigation-vertical-navigation-item-title-level-2-color--large: color(text) !default;
$header-navigation-vertical-navigation-item-title-level-2-font-size--large: rem-calc(12) !default;
$header-navigation-vertical-navigation-item-title-level-2-font-weight--large: normal !default;
// Item icon
// Left and right icon
$header-navigation-item-icon-item-icon-padding: rem-calc(5) !default;
$header-navigation-link-item-icon-link-hover-name-shift--large: rem-calc(-1) !default; // fix for jumping on hover
// Top icon
$header-navigation-item-icon-top-item-icon-top: rem-calc(-14) !default;
$header-navigation-item-icon-top-item-icon-top--large: rem-calc(-13) !default;
$header-navigation-item-icon-top-item-icon-bottom: 0 !default;
$header-navigation-item-icon-top-item-icon-bottom--large: 0 !default;

@mixin lora-components-navigation {
    .c-navigation {
        align-items: stretch;
        display: flex;
        flex: $header-navigation-flex;

        @include breakpoint(xlarge) {
            flex: $header-navigation-flex--large;
        }
    }

    // LISTS
    .c-navigation__list {
        list-style: none;
        margin: $header-navigation-list-margin;

        &:not(.m-level-1) {
            @include breakpoint(large down) {
                @include lora-animation-collapsed;
            }
        }
    }

    .c-navigation__list.m-level-1 {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;

        @include breakpoint(large down) {
            background: $header-navigation-list-level-1-background;
        }
        @include breakpoint(xlarge) {
            align-items: stretch;
            flex-flow: row nowrap;
            justify-content: $header-navigation-list-level-1-justify-content--large;
        }
    }

    .c-navigation__list.m-level-2 {
        display: flex;

        @include breakpoint(large down) {
            flex-flow: column nowrap;
        }
        @include breakpoint(xlarge) {
            flex-flow: row nowrap;
            padding: $header-navigation-list-level-2-padding--large;
            margin: $header-navigation-list-level-2-margin--large;
        }
    }

    .c-navigation__list.m-level-3 {
        @include breakpoint(xlarge) {
            display: flex;
            flex-flow: column;
            margin: $header-navigation-list-level-3-margin--large;
        }
    }

    // EO LISTS

    // ITEMS
    .c-navigation__item {
        display: block;
        text-align: $global-left;

        @include breakpoint(large down) {
            border-bottom: $header-navigation-item-border-bottom;
        }
    }

    .c-navigation__item.m-expanded > .c-navigation__list,
    .c-navigation__item.m-level-1.m-expanded .c-navigation__list:first-child {
        @include breakpoint(large down) {
            @include lora-animation-expanded;
        }
    }

    .c-navigation__item:first-child {
        @include breakpoint(large down) {
            border-top: $header-navigation-item-first-border-top;
        }
    }

    .c-navigation__item.m-level-1 {
        @include breakpoint(xlarge) {
            align-items: stretch;
            display: flex;
            margin: $header-navigation-item-level-1-margin--large;
            white-space: $header-navigation-item-level-1-white-space--large;
            flex-grow: $header-navigation-item-level-1-flex-grow--large;
        }
    }

    // stylelint-disable max-nesting-depth
    .c-navigation__item.m-level-2 {
        @include breakpoint(xlarge) {
            padding: $header-navigation-item-level-2-padding--large;
            width: $header-navigation-item-level-2-width--large;

            @for $i from 2 through $header-navigation-item-level-2-columns--large {
                &.m-column-#{$i} {
                    width: $i * $header-navigation-item-level-2-width--large;

                    .c-navigation__list.m-level-3 {
                        display: list-item;
                        columns: $i;
                        column-gap: $header-navigation-item-level-3-column-gap;
                    }
                }
            }
        }
    }
    // stylelint-enable max-nesting-depth

    .c-navigation__item.m-level-2.m-view-all {
        @include breakpoint(large down) {
            border: $header-navigation-item-level-2-view-all-border;
            border-width: $header-navigation-item-level-2-view-all-border-width;
        }

        @include breakpoint(xlarge) {
            display: none;
        }
    }

    .c-navigation__item.m-level-3 {
        @include breakpoint(large down) {
            border: $header-navigation-item-level-3-border;
            border-width: $header-navigation-item-level-3-border-width;
        }

        @include breakpoint(xlarge) {
            padding: $header-navigation-item-level-3-padding--large;
            white-space: $header-navigation-item-level-3-white-space--large;

            &.m-break-after {
                break-after: column;
            }
        }

        &:first-child {
            @include breakpoint(large down) {
                border: $header-navigation-item-level-3-first-border;
                border-width: $header-navigation-item-level-3-first-border-width;
            }
        }
    }

    .c-navigation__item.m-level-3.m-view-all {
        .c-navigation__link {
            color: $header-navigation-item-level-3-all-link-color;
            font: $header-navigation-item-level-3-all-link-font;
            text-transform: $header-navigation-item-level-3-all-link-text-transform;
            text-decoration: $header-navigation-item-level-3-all-link-text-decoration;

            @include breakpoint(xlarge) {
                color: $header-navigation-item-level-3-all-link-color--large;
                font: $header-navigation-item-level-3-all-link-font--large;
                text-transform: $header-navigation-item-level-3-all-link-text-transform--large;
                text-decoration: $header-navigation-item-level-3-all-link-text-decoration--large;
                margin: $header-navigation-item-level-3-all-link-margin--large;
            }

            &:hover,
            &:focus {
                color: $header-navigation-item-level-3-all-link-hover-color;
            }
        }
    }
    // EO ITEMS

    // TITLES
    .c-navigation__item-title {
        align-items: center;
        display: flex;

        @include breakpoint(large down) {
            justify-content: space-between;
            padding: $header-navigation-item-title-padding;
        }
    }

    // ITEM ACCORDION CONTROLS
    .c-navigation__item-title.m-accordion-control {
        @include breakpoint(large down) {
            @include lora-helper-accordion-control-collapsed;

            text-transform: $header-navigation-item-title-accordion-text-transform;

            & > .c-accordion__icon::after {
                color: $header-navigation-item-title-accordion-icon-color;
            }
        }
    }

    .c-navigation__item.m-expanded > .c-navigation__item-title.m-accordion-control {
        @include breakpoint(large down) {
            @include lora-helper-accordion-control-expanded;
        }
    }
    // EO ITEM ACCORDION CONTROLS

    .c-navigation__item-title.m-level-1 {
        color: $header-navigation-item-title-level-1-color;
        font-size: $header-navigation-item-title-level-1-font-size;
        position: relative;

        @include breakpoint(large down) {
            padding-#{$global-left}: $header-navigation-item-title-level-1-padding-left;
        }

        @include breakpoint(xlarge) {
            font-size: $header-navigation-item-title-level-1-font-size--large;
            color: $header-navigation-item-title-level-1-color--large;
            z-index: z(header, navigationTitle);
        }
    }

    .c-navigation__item-title.m-level-1::before { // arrow at the bottom of the top link
        @include breakpoint(xlarge) {
            background: $header-navigation-flyout-element-background--large;
            border: $header-navigation-flyout-element-border-top--large;
            border-bottom: 0;
            border-#{$global-left}: 0;
            content: '';
            height: 10px;
            #{$global-left}: 50%;
            margin-#{$global-left}: $header-navigation-flyout-element-margin-left--large;
            opacity: 0;
            position: absolute;
            top: 100%;
            transform-origin: 0 0;
            transform: rotateZ(-45deg);
            transition: opacity 0s;
            width: 10px;
        }
    }

    .c-navigation__item.m-opened .c-navigation__item-title::before,
    .c-navigation__item.m-opened:focus .c-navigation__item-title::before {
        @include breakpoint(xlarge) {
            opacity: 1;
        }
    }

    .c-navigation__item-title.m-level-2 {
        color: $header-navigation-item-title-level-2-color;
        font-size: $header-navigation-item-title-level-2-font-size;
        font-weight: $header-navigation-item-title-level-2-font-weight;

        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-2-background;
            padding-#{$global-left}: $header-navigation-item-title-level-2-padding-left;
        }

        @include breakpoint(xlarge) {
            color: $header-navigation-item-title-level-2-color--large;
            font-size: $header-navigation-item-title-level-2-font-size--large;
            font-weight: $header-navigation-item-title-level-2-font-weight--large;
            align-items: $header-navigation-item-title-level-2-align-items--large;
        }
    }

    .c-navigation__item-title.m-level-3 {
        color: $header-navigation-item-title-level-3-color;
        font-size: $header-navigation-item-title-level-3-font-size;

        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-3-background;
            padding: 0;
        }
        @include breakpoint(xlarge) {
            font-size: $header-navigation-item-title-level-3-font-size--large;
            color: $header-navigation-item-title-level-3-color--large;
            line-height: $header-navigation-item-title-level-3-line-height--large;
        }
    }
    // EO ITEM

    // ITEM EXPANDED TITLE
    .c-navigation__item.m-level-1.m-expanded > .c-navigation__item-title {
        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-1-expanded-background;
            color: $header-navigation-item-title-level-1-expanded-color;
            font: $header-navigation-item-title-level-1-expanded-font;
        }
    }

    .c-navigation__item.m-level-2.m-expanded > .c-navigation__item-title {
        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-2-expanded-background;
            color: $header-navigation-item-title-level-2-expanded-color;
            border-bottom: $header-navigation-item-title-level-2-expanded-border-bottom;
        }
    }
    // EO ITEM EXPANDED TITLE

    // NAVIGATION FLYOUT (for large viewports)
    .c-navigation__flyout-element {
        @include breakpoint(xlarge) {
            background: $header-navigation-flyout-element-background--large;
            border-bottom: $header-navigation-flyout-element-border-bottom--large;
            border-top: $header-navigation-flyout-element-border-top--large;
            box-shadow: $header-navigation-flyout-element-box-shadow--large;
            #{$global-left}: 0;
            margin: 0 -50vw;
            padding: 0 50vw;
            position: absolute;
            #{$global-right}: 0;
            top: 100%;
            display: none;
            z-index: z(header, meganav);
        }

        &::before {
            @include breakpoint(xlarge) {
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
                top: 100%;
                height: vh(100);
                pointer-events: none;
                background: $header-navigation-flyout-element-before-background--large;
            }
        }
    }

    .c-navigation.m-init .c-navigation__flyout-element {
        @include breakpoint(xlarge) {
            display: block;
            visibility: hidden;
        }
    }

    .c-navigation__item.m-opened .c-navigation__flyout-element {
        @include breakpoint(xlarge) {
            display: block;
        }
    }
    // EO NAVIGATION FLYOUT (for large viewports)

    // LINKS
    .c-navigation__link {
        @include bold-link;

        color: $header-navigation-link-color;
        position: relative;
        display: inline-block;
        font-size: $header-navigation-link-font-size;
        font-weight: $header-navigation-link-font-weight;
        margin: $header-navigation-link-margin;
        text-decoration: $header-navigation-link-text-decoration;
        text-transform: $header-navigation-link-text-transform;
        z-index: 2; // stylelint-disable-line
        letter-spacing: $header-navigation-link-letter-spacing;

        @include breakpoint(xlarge) {
            display: block;
            letter-spacing: $header-navigation-link-letter-spacing--large;
        }

        &:hover {
            color: $header-navigation-link-color;
            font-weight: $header-navigation-link-hover-font-weight;
        }

        &:is(span) {
            @include breakpoint(large down) {
                pointer-events: none;
            }
        }
    }

    .c-navigation__link.m-without-subcategories {
        flex-grow: 1;
    }

    .c-navigation__link.m-not-clickable-for-mobile {
        @include breakpoint(large down) {
            pointer-events: none;
        }
    }

    .c-navigation__link.m-level-1 {
        color: $header-navigation-link-level-1-color;
        font-family: $header-navigation-link-level-1-font-family;
        font-weight: $header-navigation-link-level-1-font-weight;
        font-size: $header-navigation-link-level-1-font-size;
        text-transform: $header-navigation-link-level-1-text-transform;

        @include breakpoint(xlarge) {
            color: $header-navigation-link-level-1-color--large;
            font-weight: $header-navigation-link-level-1-font-weight--large;
            font-size: $header-navigation-link-level-1-font-size--large;
            font-family: $header-navigation-link-level-1-font-family--large;
            padding: $header-navigation-link-level-1-padding--large;
            position: relative;
            text-transform: $header-navigation-link-level-1-text-transform--large;
            text-align: $header-navigation-link-level-1-text-align--large;
            letter-spacing: $header-navigation-link-level-1-letter-spacing--large;
        }

        &:hover,
        &:focus {
            color: $header-navigation-link-level-1-hover-color;
            font-weight: $header-navigation-link-level-1-hover-font-weight;

            @include breakpoint(xlarge) {
                color: $header-navigation-link-level-1-hover-color--large;
            }
        }
    }

    .c-navigation__link.m-level-2 {
        color: $header-navigation-link-level-2-color;
        font-family: $header-navigation-link-level-2-font-family;
        font-size: $header-navigation-link-level-2-font-size;
        font-weight: $header-navigation-link-level-2-font-weight;
        text-transform: $header-navigation-link-level-2-text-transform;

        @include breakpoint(xlarge) {
            color: $header-navigation-link-level-2-color--large;
            font-family: $header-navigation-link-level-2-font-family--large;
            font-weight: $header-navigation-link-level-2-font-weight--large;
            font-size: $header-navigation-link-level-2-font-size--large;
            text-transform: $header-navigation-link-level-2-text-transform--large;
            letter-spacing: $header-navigation-link-level-2-letter-spacing--large;
            flex-grow: 0;
        }

        &:hover,
        &:focus {
            color: $header-navigation-link-level-2-hover-color;
            font-weight: $header-navigation-link-level-2-hover-font-weight;
        }
    }

    .c-navigation__link.m-level-3 {
        color: $header-navigation-link-level-3-color;
        font-weight: $header-navigation-link-level-3-font-weight;
        text-transform: $header-navigation-link-level-3-text-transform;
        font-size: $header-navigation-link-level-3-font-size;

        @include breakpoint(large down) {
            padding: $header-navigation-link-level-3-padding--medium-down;
        }

        @include breakpoint(xlarge) {
            color: $header-navigation-link-level-3-color--large;
            font-size: $header-navigation-link-level-3-font-size--large;
            font-weight: $header-navigation-link-level-3-font-weight--large;
            text-transform: $header-navigation-link-level-3-text-transform--large;
            flex-grow: 0;
        }

        &:hover,
        &:focus {
            color: $header-navigation-link-level-3-hover-color;
            font-weight: $header-navigation-link-level-3-hover-font-weight;
        }
    }

    // EO LINKS

    // ACCESSABILITY BUTTON
    .c-navigation__button {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1; // stylelint-disable-line

        @include breakpoint(xlarge) {
            pointer-events: none;
        }
    }

    .c-navigation__button.m-level-1:focus {
        &::after {
            @include breakpoint(xlarge) {
                @include svg-icon($header-navigation-item-button-level-1-focus-icon, $header-navigation-item-button-level-1-focus-icon-color, 100%, center, no-repeat, $usesvg: $header-navigation-item-button-level-1-focus-icon-usesvg);
                @include horizontal-center;

                content: '';
                height: $header-navigation-item-button-level-1-focus-icon-height;
                width: $header-navigation-item-button-level-1-focus-icon-width;
                bottom: $header-navigation-item-button-level-1-focus-icon-bottom;
            }
        }

        .c-navigation__item.m-opened & {
            &::after {
                @include breakpoint(xlarge) {
                    @include svg-icon($header-navigation-item-button-level-1-expanded-focus-icon, $header-navigation-item-button-level-1-focus-icon-color, 100%, center, no-repeat, $usesvg: $header-navigation-item-button-level-1-expanded-focus-icon-usesvg);
                }
            }
        }
    }

    .c-navigation__button.m-level-2 {
        @include breakpoint(xlarge) {
            display: none;
        }
    }
    // EO ACCESSABILITY BUTTON

    .c-navigation__flyout-slot {
        @include breakpoint(large down) {
            display: none;
        }

        @include breakpoint(xlarge) {
            padding: $header-navigation-flyout-slot-padding--large;
        }
    }

    .c-navigation .c-navigation-flyout {
        @include layout;

        margin: 0 auto;
    }

    .c-navigation__content {
        display: block;

        @include breakpoint(xlarge) {
            color: $header-navigation-content-color--large;
            font: $header-navigation-content-font--large;
            margin: $header-navigation-content-margin--large;
            text-decoration: $header-navigation-content-text-decoration--large;
        }

        &:hover,
        &:focus {
            @include breakpoint(xlarge) {
                color: $header-navigation-content-hover-color--large;
                font: $header-navigation-content-hover-font--large;
            }
        }
    }

    .c-navigation__content-title {
        @include breakpoint(xlarge) {
            color: $header-navigation-content-title-color--large;
            font: $header-navigation-content-title-font--large;
            margin: $header-navigation-content-title-margin--large;
            text-transform: $header-navigation-content-title-text-transform--large;
        }
    }

    .c-navigation__locale .c-navigation__item {
        border-top: 0;
    }

    .c-navigation__locale-content {
        padding: $header-navigation-locale-content-padding;
        border-top: $header-navigation-locale-content-border-top;
    }

    .c-navigation__item.m-level-1.m-vertical {
        .c-navigation__list.m-level-2 {
            @include breakpoint(xlarge) {
                align-content: $header-navigation-vertical-navigation-list-level-2-align-content--large;
                flex-flow: $header-navigation-vertical-navigation-list-level-2-flex-flow--large;
                max-height: $header-navigation-vertical-navigation-list-level-2-max-height--large;
                width: 100%;
            }
        }

        .c-navigation__item.m-level-2 {
            @include breakpoint(xlarge) {
                padding: $header-navigation-vertical-navigation-item-level-2-padding--large;
            }
        }

        .c-navigation__item-title.m-level-2 {
            @include breakpoint(xlarge) {
                color: $header-navigation-vertical-navigation-item-title-level-2-color--large;
                font-size: $header-navigation-vertical-navigation-item-title-level-2-font-size--large;
                font-weight: $header-navigation-vertical-navigation-item-title-level-2-font-weight--large;
            }
        }

        .m-opened.c-navigation__flyout-element {
            @include breakpoint(large) {
                display: flex;
            }
        }
    }

    .c-navigation__item.m-icon-left,
    .c-navigation__item.m-icon-right {
        .c-navigation__link-content {
            display: flex;
            align-items: center;
        }

        .c-navigation__link {
            &:hover,
            &:focus {
                @include breakpoint(xlarge) {
                    .c-navigation__link-name { /* stylelint-disable-line max-nesting-depth */
                        margin-left: $header-navigation-link-item-icon-link-hover-name-shift--large;
                        margin-right: $header-navigation-link-item-icon-link-hover-name-shift--large;
                    }
                }
            }
        }
    }

    .c-navigation__item.m-icon-left {
        .c-navigation__item-icon {
            padding-right: $header-navigation-item-icon-item-icon-padding;
        }
    }

    .c-navigation__item.m-icon-right {
        .c-navigation__item-icon {
            order: 1;
            padding-left: $header-navigation-item-icon-item-icon-padding;
        }
    }

    .c-navigation__item.m-icon-top {
        .c-navigation__item-icon {
            position: absolute;
            top: $header-navigation-item-icon-top-item-icon-top;
            bottom: $header-navigation-item-icon-top-item-icon-bottom;

            @include breakpoint(xlarge) {
                @include horizontal-center;

                top: $header-navigation-item-icon-top-item-icon-top--large;
                bottom: $header-navigation-item-icon-top-item-icon-bottom--large;
            }
        }
    }
}
