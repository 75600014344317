$loyalty-reviews-info-columns: 5 of 12 !default;
$loyalty-reviews-info-float: left !default;
$loyalty-reviews-write-float: right !default;

@mixin apaclayer-components-loyalty-reviews {
    .c-loyalty-reviews__info {
        float: $loyalty-reviews-info-float;

        @include breakpoint(medium down) {
            @include cell($loyalty-reviews-info-columns);
        }
    }

    .c-loyalty-reviews__write-review {
        float: $loyalty-reviews-write-float;

        @include breakpoint(medium down) {
            clear: none;
        }
    }
}