$loyalty-rewards-margin: rem-calc(30 0 0) !default;
$loyalty-rewards-margin--large: rem-calc(30 0) !default;
$loyalty-rewards-cart-margin: rem-calc(0 0 20) !default;
$loyalty-rewards-cart-margin--large: $loyalty-rewards-cart-margin !default;
$loyalty-rewards-actions-width: 100% !default;
$loyalty-rewards-button-margin: rem-calc(10 0 0) !default;
$loyalty-rewards-button-disabled-color: color(disabled) !default;
$loyalty-rewards-button-disabled-background-color: color(element-background) !default;
$loyalty-rewards-button-disabled-border: 1px solid transparent !default;
$loyalty-rewards-redeem-margin: rem-calc(15 0 45) !default;
$loyalty-rewards-redeem-margin--large: $loyalty-rewards-redeem-margin !default;

$loyalty-rewards-content-tile-section-padding: rem-calc(30 0 0) !default;
$loyalty-rewards-content-tile-section-padding--large: rem-calc(0 30 0 40) !default;
$loyalty-rewards-content-tile-image-margin: rem-calc(15 0 30) !default;
$loyalty-rewards-content-tile-image-margin--large: 0 !default;
$loyalty-rewards-content-tile-title-margin: 0 !default;
$loyalty-rewards-content-tile-description-font-size: rem-calc(16) !default;
$loyalty-rewards-content-tile-description-font-size--large: rem-calc(16) !default;

$loyalty-rewards-eliterewards-background: color(light) !default;
$loyalty-rewards-eliterewards-join-background--large: $loyalty-rewards-eliterewards-background !default;
$loyalty-rewards-eliterewards-join-background: color(element-background) !default;

$loyalty-rewards-eliterewards-padding: rem-calc(0 20 10) !default;
$loyalty-rewards-eliterewards-padding--large: rem-calc(25 30) !default;
$loyalty-rewards-eliterewards-margin: rem-calc(0 0 15) !default;
$loyalty-rewards-eliterewards-margin--large: rem-calc(30 0 15) !default;

$loyalty-rewards-product-grid-redeem-margin: 0 !default;

@mixin apaclayer-components-loyalty-rewards {
    .c-loyalty-rewards {
        margin: $loyalty-rewards-margin;

        @include breakpoint(large) {
            margin: $loyalty-rewards-margin--large;
        }
    }

    .c-loyalty-rewards-cart {
        margin: $loyalty-rewards-cart-margin;

        @include breakpoint(large) {
            margin: $loyalty-rewards-cart-margin--large;
        }
    }

    .c-loyalty-rewards__actions {
        
        @include breakpoint(medium down) {
            width: $loyalty-rewards-actions-width;
        }
    }

    .c-rewards-button__button {
        margin: $loyalty-rewards-button-margin;
    }

    .c-loyalty-rewards__redeem.c-product-tile__button {
        margin: $loyalty-rewards-redeem-margin;

        @include breakpoint(large) {
            margin: $loyalty-rewards-redeem-margin--large;
        }
    }

    .c-loyalty-rewards__disable {
        background-color: $loyalty-rewards-button-disabled-background-color;
        border: $loyalty-rewards-button-disabled-border;
        color: $loyalty-rewards-button-disabled-color;
        cursor: not-allowed;
        pointer-events: none;
    }

    .c-loyalty-rewards__disable:hover {
        background-color: $loyalty-rewards-button-disabled-background-color;
        border: $loyalty-rewards-button-disabled-border;
        color: $loyalty-rewards-button-disabled-color;
    }

    .c-loyalty-rewards-content-tile__disable {
        cursor: not-allowed;
        pointer-events: none;
    }

    .c-loyalty-rewards-content-tile__section {
        padding: $loyalty-rewards-content-tile-section-padding;

        @include breakpoint(large) {
            padding: $loyalty-rewards-content-tile-section-padding--large;
        }
    }

    .c-loyalty-rewards-content-tile__image {
        margin: $loyalty-rewards-content-tile-image-margin;

        @include breakpoint(large) {
            margin: $loyalty-rewards-content-tile-image-margin--large;
        }
    }

    .c-loyalty-rewards-content-tile__title {
        margin: $loyalty-rewards-content-tile-title-margin;
    }

    .c-loyalty-rewards__eliterewards {
        background: $loyalty-rewards-eliterewards-background;
        padding: $loyalty-rewards-eliterewards-padding;
        margin: $loyalty-rewards-eliterewards-margin;

        @include breakpoint(large) {
            padding: $loyalty-rewards-eliterewards-padding--large;
            margin: $loyalty-rewards-eliterewards-margin--large;
        }
    }

    .c-loyalty-rewards__eliterewards.c-loyalty-rewards__eliterewards-join {
        background: $loyalty-rewards-eliterewards-join-background;

        @include breakpoint(large) {
            background: $loyalty-rewards-eliterewards-join-background--large;
        }
    }

    .c-loyalty-rewards__name-link,
    .c-loyalty-rewards__thumbnail {
        cursor: pointer;
    }

    .c-loyalty-rewards__product-grid {
        .c-loyalty-rewards__redeem {
            margin: $loyalty-rewards-product-grid-redeem-margin;
        }
    }
}
