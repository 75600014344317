$loyalty-onoffer-actions-margin: 0 !default;
$loyalty-onoffer-button-width: 100% !default;
$loyalty-onoffer-content-margin-bottom: 0 !default;
$loyalty-onoffer-margin: rem-calc(0 0 20) !default;
$loyalty-onoffer-margin--large: rem-calc(0 30) !default;
$loyalty-onoffer-padding: 0;
$loyalty-onoffer-padding--large: rem-calc(0 20) !default;
$loyalty-onoffer-title-margin: rem-calc(0 0 20) !default;
$loyalty-onoffer-title-margin--large: rem-calc(0 0 20 0) !default;

@mixin apaclayer-components-loyalty-onoffer {
    .c-loyalty-onoffer {
        margin: $loyalty-onoffer-margin;
        padding: $loyalty-onoffer-padding;

        @include breakpoint(large) {
            margin: $loyalty-onoffer-margin--large;
            padding: $loyalty-onoffer-padding--large;
        }
    }

    .c-loyalty-onoffer__title {
        margin: $loyalty-onoffer-title-margin;

        @include breakpoint(large) {
            margin: $loyalty-onoffer-title-margin--large;
        }
    }

    .c-loyalty-onoffer__actions {
        margin: $loyalty-onoffer-actions-margin;

        .c-button {
            width: $loyalty-onoffer-button-width;
        }
    }

    .c-loyalty-onoffer__content {
        margin-bottom: $loyalty-onoffer-content-margin-bottom;
    }
}