@import "@root/05-components/cart/cart-buttons";

$cart-buttons-alert-content-color: #f00;

// stylelint-disable declaration-no-important
.l-ngcheckout__main {
    .c-field__error-message {
        &.m-veritrans-custom {
            color: $cart-buttons-alert-content-color;
            font-weight: bold;
        }
    }
}