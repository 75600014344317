$loyalty-progress-start-point-current-z-index: 4 !default;
$loyalty-progress-bar-wrapper-background-color: color(border) !default;
$loyalty-progress-bar-background-color: color(primary-active) !default;
$loyalty-progress-bar-start-point-background-color: color(primary-active) !default;
$loyalty-progress-bar-start-point-width: rem-calc(14) !default;
$loyalty-progress-bar-start-point-height: rem-calc(14) !default;
$loyalty-progress-bar-start-point-border-radius: rem-calc(20) !default;
$loyalty-progress-bar-start-point-border: rem-calc(3) solid color(primary-active) !default;
$loyalty-progress-bar-start-point-locked-background-color: color(light) !default;
$loyalty-progress-bar-start-point-locked-border: rem-calc(3) solid color(border) !default;
$loyalty-progress-tier-tooltip-z-index: 3 !default;
$loyalty-progress-tier-tooltip-offset--large: rem-calc(115) !default;
$loyalty-progress-tier-tooltip-padding: rem-calc(20) !default;
$loyalty-progress-tier-tooltip-background: color(light) !default;
$loyalty-progress-tier-tooltip-height: rem-calc(95) !default;
$loyalty-progress-tier-tooltip-width: rem-calc(300) !default;
$loyalty-progress-tier-tooltip-width--large: rem-calc(450) !default;
$loyalty-progress-tier-tooltip-last-left--large: rem-calc($loyalty-progress-tier-tooltip-width--large - $loyalty-progress-tier-tooltip-offset--large) !default;
$loyalty-progress-tier-title-font: 700 #{rem-calc(16)} / 1.45 $font-primary !default;
$loyalty-progress-tier-title-font--large: $loyalty-progress-tier-title-font !default;
$loyalty-progress-tier-subtitle-font: 700 #{rem-calc(14)} / 1.45 $font-primary !default;
$loyalty-progress-tier-title-text-align: center !default;
$loyalty-progress-tier-subtitle-color: color(text-secondary) !default;
$loyalty-progress-tier-column-text-align: center !default;
$loyalty-progress-tier-subtitle-display: block !default;
$loyalty-progress-tier-card-title-font: 700 #{rem-calc(14)} / 1.45 $font-primary !default;
$loyalty-progress-tier-card-title-color: null !default;
$loyalty-progress-tier-card-subtitle-font: 500 #{rem-calc(12)} / 1.45 $font-primary !default;
$loyalty-progress-tier-card-subtitle-color: color(text-secondary) !default;
$loyalty-progress-tier-card-subtitle-margin: null !default;
$loyalty-progress-tier-card-subtitle-margin--large: $loyalty-progress-tier-card-subtitle-margin !default;
$loyalty-progress-tier-card-border: 1px solid color(border) !default;
$loyalty-progress-tier-card-details-padding: rem-calc(15) !default;
$loyalty-progress-tier-card-details-padding--large: $loyalty-progress-tier-card-details-padding !default;
$loyalty-progress-tier-card-benefits-icon: "chevron-down" !default;
$loyalty-progress-tier-card-benefits-icon-color: color(dark) !default;
$loyalty-progress-tier-card-benefits-font: 700 #{rem-calc(12)} / 1.45 $font-primary !default;
$loyalty-progress-tier-card-benefits-margin: rem-calc(10) 0 0 0 !default;
$loyalty-progress-tier-card-benefits-margin--large: $loyalty-progress-tier-card-benefits-margin !default;
$loyalty-progress-bar-wrapper-margin-bottom: $loyalty-progress-tier-tooltip-height + rem-calc(30) !default;
$loyalty-progress-bar-wrapper-empty-margin-bottom: rem-calc(30) !default;
$loyalty-progress-tier-card-benefits-content-list-item-padding: rem-calc(10 0 0 20) !default;
$loyalty-progress-tier-card-benefits-content-list-item-font: null !default;
$loyalty-progress-tier-card-benefits-content-list-item-color: null !default;
$loyalty-progress-tier-card-benefits-list-item-icon: "tick" !default;
$loyalty-progress-tier-card-benefits-list-item-icon-color: color(dark) !default;
$loyalty-progress-tier-card-benefits-content-dob-qualify-margin: rem-calc(20 0 0) !default;
$loyalty-progress-tier-card-benefits-content-list-item-icon-width: rem-calc(15) !default;
$loyalty-progress-tier-card-benefits-content-list-item-icon-height: rem-calc(9) !default;
$loyalty-progress-tier-card-benefits-content-list-item-icon-margin: rem-calc(5 0 0) !default;
$loyalty-progress-tier-card-wrapper-padding: null !default;
$loyalty-progress-tier-card-wrapper-padding--large: $loyalty-progress-tier-card-wrapper-padding !default;
$loyalty-progress-tier-card-opened-margin: null !default;
$loyalty-progress-tier-card-opened-margin--large: $loyalty-progress-tier-card-opened-margin !default;
$loyalty-progress-tiers-padding: null !default;
$loyalty-progress-tiers-padding--large: $loyalty-progress-tiers-padding !default;
$loyalty-progress-tier-header-font: null !default;
$loyalty-progress-tier-header-font--large: $loyalty-progress-tier-header-font !default;
$loyalty-progress-tier-header-current-font: null !default;
$loyalty-progress-tier-header-current-font--large: $loyalty-progress-tier-header-current-font !default;
$loyalty-progress-tier-title-current-font: null !default;
$loyalty-progress-tier-title-current-font--large: $loyalty-progress-tier-title-current-font !default;
$loyalty-progress-tier-subtitle-current-font: null !default;
$loyalty-progress-tier-subtitle-current-font--large: $loyalty-progress-tier-subtitle-current-font !default;

// progress bar
$loyalty-progress-bar-z-index: 1 !default;
$loyalty-progress-bar-bottom: rem-calc(-24) !default;
$loyalty-progress-bar-background-color: color(primary-active) !default;
$loyalty-progress-bar-first-left: rem-calc(10) !default;
$loyalty-progress-bar-first-left--large: rem-calc(15) !default;
$loyalty-progress-bar-last-left: rem-calc(-10) !default;
$loyalty-progress-bar-last-left--large: rem-calc(-15) !default;
$loyalty-progress-bar-current-height: rem-calc(5) !default;
$loyalty-progress-bar-locked-height: 0 !default;

@mixin apaclayer-components-loyalty-progress {
    .c-loyalty-progress__tier-bar-wrapper {
        display: grid;
        overflow-x: scroll;

        @include breakpoint(large) {
            overflow-x: initial;
        }

        .m-locked:not(.c-loyalty-progress__bar-start-point) {
            opacity: 1;
        }
    }

    .c-loyalty-progress__tier-cards {
        .m-locked:not(.m-opened) {
            opacity: 0.5;
        }

        .c-loyalty-progress__tier-card-wrapper {
            padding: $loyalty-progress-tier-card-wrapper-padding;
            
            @include breakpoint(large) {
                padding: $loyalty-progress-tier-card-wrapper-padding--large;
            }
        }
    }

    .c-loyalty-progress__tier-header {
        font: $loyalty-progress-tier-header-font;
        
        @include breakpoint(large) {
            font: $loyalty-progress-tier-header-font--large;
        }
    }

    .c-loyalty-progress__tier-title {
        font: $loyalty-progress-tier-title-font;

        @include breakpoint(large) {
            font: $loyalty-progress-tier-title-font--large;
        }
    }

    .c-loyalty-progress__tier-subtitle {
        font: $loyalty-progress-tier-subtitle-font;
        color: $loyalty-progress-tier-subtitle-color;
        display: $loyalty-progress-tier-subtitle-display;
    }

    .c-loyalty-progress__bar-wrapper {
        @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: margin);

        display: flex;
        justify-content: space-around;
        align-items: center;
        height: rem-calc(3);
        background-color: $loyalty-progress-bar-wrapper-background-color;
        position: relative;
        margin-bottom: $loyalty-progress-bar-wrapper-margin-bottom;
    }

    .c-loyalty-progress__bar {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            background-color: $loyalty-progress-bar-background-color;
            left: 0;
            bottom: $loyalty-progress-bar-bottom;
            z-index: $loyalty-progress-bar-z-index;
        }

        &.m-first {
            &::before {
                left: $loyalty-progress-bar-first-left;
                width: 100%;

                @include breakpoint(large) {
                    left: $loyalty-progress-bar-first-left--large;
                    width: calc(100% - 15px);
                }
            }
        }

        &.m-last {
            &::before {
                left: $loyalty-progress-bar-last-left;

                @include breakpoint(large) {
                    left: $loyalty-progress-bar-last-left--large;
                }
            }
        }

        &.m-locked {
            &::before {
                height: $loyalty-progress-bar-current-height;
            }
        }

        &.m-current {
            &::before {
                height: $loyalty-progress-bar-current-height;
            }

            ~ .m-locked {
                &::before {
                    height: $loyalty-progress-bar-locked-height;
                }
            }

            .c-loyalty-progress__tier-header {
                font: $loyalty-progress-tier-header-current-font;

                @include breakpoint(large) {
                    font: $loyalty-progress-tier-header-current-font--large;
                }
            }

            .c-loyalty-progress__tier-title {
                font: $loyalty-progress-tier-title-current-font;

                @include breakpoint(large) {
                    font: $loyalty-progress-tier-title-current-font--large;
                }
            }

            .c-loyalty-progress__tier-subtitle {
                font: $loyalty-progress-tier-subtitle-current-font;

                @include breakpoint(large) {
                    font: $loyalty-progress-tier-subtitle-current-font--large;
                }
            }
        }
    }

    .c-loyalty-progress__bar-start-point {
        background-color: $loyalty-progress-bar-start-point-background-color;
        width: $loyalty-progress-bar-start-point-width;
        height: $loyalty-progress-bar-start-point-height;
        border-radius: $loyalty-progress-bar-start-point-border-radius;
        border: $loyalty-progress-bar-start-point-border;
        position: relative;

        &.m-current ~ .m-locked {
            background-color: $loyalty-progress-bar-start-point-locked-background-color;
            border: $loyalty-progress-bar-start-point-locked-border;
        }

        &.m-current {
            &::after {
                content: '';
                height: rem-calc(20);
                width: rem-calc(20);
                background: color(light);
                box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
                position: absolute;
                transform: rotate(45deg);
                left: rem-calc(-5);
                top: rem-calc(16);
                z-index: $loyalty-progress-start-point-current-z-index;
            }
        }
    }

    .c-loyalty-progress__bar-wrapper.m-empty {
        margin-bottom: $loyalty-progress-bar-wrapper-empty-margin-bottom;

        .m-current {
            &::after {
                display: none;
            }
        }
    }

    .c-loyalty-progress__tier-columns {
        display: flex;
        margin: 0 0 rem-calc(20);
    }

    .c-loyalty-progress__tier-column {
        @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: padding);

        width: 90vw;
        text-align: $loyalty-progress-tier-column-text-align;
        flex: 1;

        @include breakpoint(large) {
            width: auto;
        }
    }

    .c-loyalty-progress__tier-tooltip {
        display: flex;
        align-items: center;
        position: absolute;
        width: $loyalty-progress-tier-tooltip-width;
        max-width: 90vw;
        height: $loyalty-progress-tier-tooltip-height;
        background: $loyalty-progress-tier-tooltip-background;
        padding: $loyalty-progress-tier-tooltip-padding;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: $loyalty-progress-tier-tooltip-z-index;
        left: -$loyalty-progress-tier-tooltip-width / 2;
        top: rem-calc(23);

        @include breakpoint(large) {
            width: $loyalty-progress-tier-tooltip-width--large;
            left: -$loyalty-progress-tier-tooltip-width--large / 2;
        }

        &.m-first {
            @include breakpoint(large) {
                left: (-$loyalty-progress-tier-tooltip-width--large / 2 + $loyalty-progress-tier-tooltip-offset--large);
            }
        }

        &.m-last {
            @include breakpoint(large) {
                left: initial;
                right: (-$loyalty-progress-tier-tooltip-width--large / 2 + $loyalty-progress-tier-tooltip-offset--large);
            }
        }
    }

    .c-loyalty-progress__tier-card {
        border: $loyalty-progress-tier-card-border;

        &.m-opened {
            margin: $loyalty-progress-tier-card-opened-margin;

            @include breakpoint(large) {
                margin: $loyalty-progress-tier-card-opened-margin--large;
            }
        }
    }

    .c-loyalty-progress__tier-card-details {
        padding: $loyalty-progress-tier-card-details-padding;

        @include breakpoint(large) {
            padding: $loyalty-progress-tier-card-details-padding--large;
        }
    }

    .c-loyalty-progress__tier-card-title {
        font: $loyalty-progress-tier-card-title-font;
        color: $loyalty-progress-tier-card-title-color;
    }

    .c-loyalty-progress__tier-card-subtitle {
        font: $loyalty-progress-tier-card-subtitle-font;
        color: $loyalty-progress-tier-card-subtitle-color;
        margin: $loyalty-progress-tier-card-subtitle-margin;

        @include breakpoint(large) {
            margin: $loyalty-progress-tier-card-subtitle-margin--large;
        }
    }

    .c-loyalty-progress__tier-card-benefits {
        text-align: right;
        margin: $loyalty-progress-tier-card-benefits-margin;

        @include breakpoint(large) {
            margin: $loyalty-progress-tier-card-benefits-margin--large;
        }

        &.m-opened {
            .c-loyalty-progress__tier-card-benefits-content {
                display: block;
            }
        }
    }

    .c-loyalty-progress__tier-card-benefits-title {
        font: $loyalty-progress-tier-card-benefits-font;
        cursor: pointer;

        &::after {
            content: '';
            display: inline-block;
            width: rem-calc(15);
            height: rem-calc(9);
            margin: rem-calc(0 0 0 10);

            @include svg-icon($loyalty-progress-tier-card-benefits-icon, $loyalty-progress-tier-card-benefits-icon-color, 100%, center, no-repeat);

            .m-opened & {
                transform: rotate(-180deg);
            }
        }
    }

    .c-loyalty-progress__tier-card-benefits-content {
        display: none;
    }

    .c-loyalty-progress__tier-card-benefits-content-list-item {
        padding: $loyalty-progress-tier-card-benefits-content-list-item-padding;
        position: relative;
        font: $loyalty-progress-tier-card-benefits-content-list-item-font;
        color: $loyalty-progress-tier-card-benefits-content-list-item-color;

        &.m-tick {
            &::before {
                content: '';
                display: inline-block;
                width: $loyalty-progress-tier-card-benefits-content-list-item-icon-width;
                height: $loyalty-progress-tier-card-benefits-content-list-item-icon-height;
                margin: $loyalty-progress-tier-card-benefits-content-list-item-icon-margin;
                position: absolute;
                left: 0;
    
                @include svg-icon($loyalty-progress-tier-card-benefits-list-item-icon, $loyalty-progress-tier-card-benefits-list-item-icon-color, 100%, left center, no-repeat);
            }
        }
    }

    .c-loyalty-progress__tier-card-benefits-content-dob-qualify {
        margin: $loyalty-progress-tier-card-benefits-content-dob-qualify-margin;
    }

    .c-loyalty-progress__tiers {
        padding: $loyalty-progress-tiers-padding;

        @include breakpoint(large) {
            padding: $loyalty-progress-tiers-padding--large;
        }
    }
}
