$header-simple-search-background: color(light) !default;
$header-simple-search-background--large: null !default;
$header-simple-search-transition: opacity 0.3s !default;
$header-simple-search-padding: rem-calc(10 15 15) !default;
$header-simple-search-padding--large: rem-calc(0) !default;
$header-simple-search-overflow-x: hidden !default;
$header-simple-search-overflow-y: null !default;
$header-simple-search-offset--large: rem-calc(80) !default;
$header-simple-search-input-group-background: $header-simple-search-background !default;
$header-simple-search-input-group-background--large: null !default;
$header-simple-search-input-group-font-family: null !default;
$header-simple-search-input-group-font-family--large: null !default;
$header-simple-search-input-z-index: 0 !default;
$header-simple-search-results-background: color(global-background) !default;
$header-simple-search-results-background--large: null !default;
$header-simple-search-results-margin: 0 !default;
$header-simple-search-results-margin--large: rem-calc(0) !default;
$header-simple-search-results-border-top: none !default;
$header-simple-search-results-border-top--large: none !default;
$header-simple-search-button-margin: rem-calc(5 0 0) !default;
$header-simple-search-button-margin--large: rem-calc(0 15 0 0) !default;
$header-simple-search-button-icon: search !default;
$header-simple-search-button-width: rem-calc(20) !default;
$header-simple-search-button-width--large: rem-calc(30) !default;
$header-simple-search-button-height: rem-calc(20) !default;
$header-simple-search-button-height--large: rem-calc(30) !default;
$header-simple-search-button-icon-color: color(element-background) !default;
$header-simple-search-button-icon-color--large: color(primary) !default;
$header-simple-search-button-icon-usesvg: true !default;
$header-simple-search-close-button-icon: cross !default;
$header-simple-search-close-button-icon-color: color(primary) !default;
$header-simple-search-close-button-icon-height: rem-calc(15) !default;
$header-simple-search-close-button-icon-width: rem-calc(15) !default;
$header-simple-search-close-button-icon-usesvg: true !default;
$header-simple-search-close-button-height: rem-calc(40) !default;
$header-simple-search-close-button-width: rem-calc(40) !default;
$header-simple-search-close-button-margin: rem-calc(0 -12 0 0) !default;
$header-simple-search-clear-button-icon: cross !default;
$header-simple-search-clear-button-icon-color: color(primary) !default;
$header-simple-search-clear-button-icon-usesvg: true !default;
$header-simple-search-clear-button-background--large: null !default;
$header-simple-search-clear-button-icon-height: rem-calc(15) !default;
$header-simple-search-clear-button-icon-width: rem-calc(15) !default;
$header-simple-search-clear-button-width: rem-calc(20) !default;
$header-simple-search-clear-button-width--large: rem-calc(30) !default;
$header-simple-search-clear-button-height: rem-calc(20) !default;
$header-simple-search-clear-button-height--large: rem-calc(30) !default;
$header-simple-search-clear-button-margin: rem-calc(5 0 0) !default;
$header-simple-search-clear-button-margin--large: rem-calc(0 10 0 0) !default;
$header-simple-search-label-color: color(text-secondary) !default;
$header-simple-search-label-font-family: $font-primary !default;
$header-simple-search-label-font-size: rem-calc(30) !default;
$header-simple-search-label-font-weight: normal !default;
$header-simple-search-label-line-height: rem-calc(80) !default;
$header-simple-search-label-font: $header-simple-search-label-font-weight #{$header-simple-search-label-font-size} / $header-simple-search-label-line-height $header-simple-search-label-font-family !default;
$header-simple-search-label-text-transform: null !default;
$header-simple-search-label-order--large: -1 !default;
$header-simple-search-label-padding: null !default;
$header-simple-search-label-padding--large: null !default;
$header-simple-search-field-border: 0 !default;
$header-simple-search-field-hover-border: 0 !default;
$header-simple-search-field-hover-box-shadow: none !default;
$header-simple-search-field-order: 2 !default;
$header-simple-search-field-order--large: 0 !default;
$header-simple-search-field-margin: 0 !default;
$header-simple-search-field-font-size: rem-calc(16) !default;
$header-simple-search-field-font-size--large: rem-calc(30) !default;
$header-simple-search-field-font-family: null !default;
$header-simple-search-field-font-family--large: null !default;
$header-simple-search-field-color--large: null !default;
$header-simple-search-field-font-weight: normal !default;
$header-simple-search-field-font-weight--large: null !default;
$header-simple-search-field-placeholder-color: color(text-secondary) !default;
$header-simple-search-field-padding: null !default;
$header-simple-search-field-padding--large: rem-calc(0 10) !default;
$header-simple-search-field-height: rem-calc(40) !default;
$header-simple-search-field-height--large: null !default;
$header-simple-search-field-text-transform: null !default;
$header-simple-search-field-text-transform--large: null !default;
$header-simple-search-field-background: null !default;
$header-simple-search-field-background--large: null !default;
$header-simple-search-field-radius: rem-calc(23) !default;
$header-simple-search-search-button-active-box-shadow: null !default;
$header-simple-search-search-button-active-box-shadow--large: none !default;
$header-simple-search-button-display: null !default;
$header-simple-search-button-display--large: null !default;
$header-simple-search-field-box-shadow: null !default;
$header-simple-search-field-box-shadow--large: null !default;
// Secondary
$header-simple-search-secondary-display: block !default;
$header-simple-search-secondary-display--large: flex !default;
$header-simple-search-secondary-justify-content: flex-end !default;
$header-simple-search-secondary-justify-content--large: flex-end !default;
$header-simple-search-secondary-padding: rem-calc(0 15) !default;
$header-simple-search-secondary-padding--large: rem-calc(20 0) !default;
$header-simple-search-secondary-position--large: relative !default;
$header-simple-search-secondary-input-group-background: color(global-background) !default;
$header-simple-search-secondary-input-group-background--large: color(global-background) !default;
$header-simple-search-secondary-input-group-width: 100% !default;
$header-simple-search-secondary-input-group-width--large: rem-calc(270) !default;
$header-simple-search-secondary-label-display: none !default;
$header-simple-search-secondary-label-display--large: none !default;
$header-simple-search-secondary-field-border: 0 !default;
$header-simple-search-secondary-field-border--large: 0 !default;
$header-simple-search-secondary-field-background-color: color(global-background) !default;
$header-simple-search-secondary-field-background-color--large: color(global-background) !default;
$header-simple-search-secondary-field-font-size: rem-calc(14) !default;
$header-simple-search-secondary-field-font-size--large: rem-calc(14) !default;
$header-simple-search-secondary-field-padding: rem-calc(0 10) !default;
$header-simple-search-secondary-field-padding--large: rem-calc(0 15) !default;
$header-simple-search-secondary-field-placeholder-color: color(text-secondary) !default;
$header-simple-search-secondary-field-placeholder-color--large: color(text-secondary) !default;
$header-simple-search-secondary-button-width: rem-calc(15) !default;
$header-simple-search-secondary-button-width--large: rem-calc(15) !default;
$header-simple-search-secondary-results-position: relative !default;
$header-simple-search-secondary-results-position--large: absolute !default;
$header-simple-search-secondary-results-top: 0 !default;
$header-simple-search-secondary-results-top--large: rem-calc(55) !default;
$header-simple-search-secondary-results-right: 0 !default;
$header-simple-search-secondary-results-right--large: 0 !default;
$header-simple-search-secondary-field-height: $header-simple-search-field-height !default;
$header-simple-search-secondary-field-height--large: null !default;
$header-simple-search-secondary-label-font-size--large: null !default;
$header-simple-search-secondary-label-line-height--large: null !default;
$header-simple-search-secondary-label-color--large: null !default;
// Inline (Opposite to not full screen)
$header-simple-search-inline-display: block !default;
$header-simple-search-inline-background: color(global-background) !default;
$header-simple-search-inline-background--large: color(light) !default;
$header-simple-search-inline-padding: rem-calc(10 12 0 15) !default;
$header-simple-search-inline-padding--large: rem-calc(0 15) !default;
$header-simple-search-inline-input-group-background: $header-simple-search-inline-background !default;
$header-simple-search-inline-input-group-background--large: $header-simple-search-inline-background--large !default;
$header-simple-search-inline-input-group-border-bottom: null !default;
$header-simple-search-inline-input-group-border-bottom--large: null !default;
$header-simple-search-inline-field-background: $header-simple-search-inline-background !default;
$header-simple-search-inline-field-background--large: $header-simple-search-inline-background--large !default;
$header-simple-search-inline-field-padding: rem-calc(0 10 0 0) !default;
$header-simple-search-inline-field-padding--large: rem-calc(0 10) !default;
$header-simple-search-inline-field-font-size: rem-calc(14) !default;
$header-simple-search-inline-field-font-size--large: rem-calc(30) !default;
$header-simple-search-inline-field-color: null !default;
$header-simple-search-inline-field-color--large: null !default;
$header-simple-search-inline-label-left--medium-down: rem-calc(-5) !default;
$header-simple-search-inline-label-background: $header-simple-search-inline-field-background !default;
$header-simple-search-inline-label-color--medium-down: null !default;
$header-simple-search-inline-button-icon: $header-simple-search-button-icon !default;
$header-simple-search-inline-button-icon-color: color(primary) !default;
$header-simple-search-inline-button-icon-usesvg: true !default;
$header-simple-search-inline-results-width: 100% !default;
$header-simple-search-inline-results-width--large: auto !default;
$header-simple-search-inline-results-padding: rem-calc(0 15) !default;
$header-simple-search-inline-results-padding--large: 0 !default;
$header-simple-search-inline-results-margin: 0 !default;
$header-simple-search-inline-results-margin--large: null !default;

@mixin lora-components-simple-search {
    .c-simple-search {
        background: $header-simple-search-background;
        padding: $header-simple-search-padding;
        transition: $header-simple-search-transition;
        height: vh(100);
        #{$global-left}: 0;
        overflow-y: auto;
        position: absolute;
        #{$global-right}: 0;
        top: 0;
        z-index: z(header, simplesearch);

        @include breakpoint(medium down) {
            overflow: $header-simple-search-overflow-x $header-simple-search-overflow-y;
        }

        @include breakpoint(large) {
            @include layout;

            height: auto;
            padding: $header-simple-search-padding--large;
            position: absolute;
            overflow-y: visible;
            top: 100%;
            z-index: z(header, simplesearchlarge);
            background: $header-simple-search-background--large;
        }

        .c-simple-search__input-group {
            background: $header-simple-search-input-group-background;
            font-family: $header-simple-search-input-group-font-family;

            @include breakpoint(large) {
                background: $header-simple-search-input-group-background--large;
                font-family: $header-simple-search-input-group-font-family--large;
            }
        }
    }

    .c-simple-search__results {
        background: $header-simple-search-results-background;
        margin: $header-simple-search-results-margin;
        z-index: z(header, simplesearchresultslarge);
        border-top: $header-simple-search-results-border-top;

        @include breakpoint(large) {
            margin: $header-simple-search-results-margin--large;
            background: $header-simple-search-results-background--large;
            border-top: $header-simple-search-results-border-top--large;
        }
    }

    .c-simple-search__results-group {
        max-height: vh(100, $header-simple-search-offset--large);
        overflow-y: auto;

        .c-loader::before {
            opacity: 1;
            background: $header-simple-search-results-background;
        }
    }

    .c-simple-search__results-container {
        display: none;
    }

    .c-simple-search.m-results-active {
        .c-simple-search__results-container {
            display: block;
        }

        .c-simple-search__results-list {
            display: none;
        }
    }

    .c-simple-search__input-group {
        align-items: center;
        display: flex;
    }

    .c-simple-search__search-label {
        color: $header-simple-search-label-color;
        flex: 0 0 auto;
        font: $header-simple-search-label-font;
        text-transform: $header-simple-search-label-text-transform;
        width: auto;

        @include breakpoint(large) {
            order: $header-simple-search-label-order--large;
            padding: $header-simple-search-label-padding--large;
        }

        @include breakpoint(medium down) {
            @include lora-helper-form-label-float;

            #{$global-left}: $form-label-float-left + $header-simple-search-button-width;
            max-width: calc(100% - #{$form-label-float-left} - #{$header-simple-search-button-width} - #{$header-simple-search-close-button-width});
            line-height: $header-simple-search-field-height;
            padding: $header-simple-search-label-padding;
        }
    }

    .c-simple-search__field {
        flex-grow: 1;
        order: $header-simple-search-field-order;
        border: $header-simple-search-field-border;
        font-family: $header-simple-search-field-font-family;
        font-size: $header-simple-search-field-font-size;
        font-weight: $header-simple-search-field-font-weight;
        margin: $header-simple-search-field-margin;
        height: $header-simple-search-field-height;
        text-transform: $header-simple-search-field-text-transform;
        background: $header-simple-search-field-background;
        padding: $header-simple-search-field-padding;
        box-shadow: $header-simple-search-field-box-shadow;

        @include breakpoint(large) {
            order: $header-simple-search-field-order--large;
            font-size: $header-simple-search-field-font-size--large;
            font-weight: $header-simple-search-field-font-weight--large;
            font-family: $header-simple-search-field-font-family--large;
            color: $header-simple-search-field-color--large;
            padding: $header-simple-search-field-padding--large;
            height: $header-simple-search-field-height--large;
            text-transform: $header-simple-search-field-text-transform--large;
            background: $header-simple-search-field-background--large;
            box-shadow: $header-simple-search-field-box-shadow--large;
        }

        &::placeholder {
            color: $header-simple-search-field-placeholder-color;
        }

        &:focus,
        &:hover {
            border: $header-simple-search-field-hover-border;
            box-shadow: $header-simple-search-field-hover-box-shadow;
        }

        @include breakpoint(medium down) {
            &:focus + .c-simple-search__search-label,
            &:not(:placeholder-shown) + .c-simple-search__search-label {
                @include lora-helper-form-label-float-focused;
            }
        }
    }

    .c-simple-search__button {
        cursor: pointer;
        flex: 0 0 auto;
        margin: $header-simple-search-button-margin;
        text-align: center;
        display: $header-simple-search-button-display;

        @include breakpoint(large) {
            margin: $header-simple-search-button-margin--large;
            display: $header-simple-search-button-display--large;
        }

        &::after {
            @include svg-icon($header-simple-search-button-icon, $header-simple-search-button-icon-color, 100%, left, no-repeat, $usesvg: $header-simple-search-button-icon-usesvg);

            content: '';
            display: inline-block;
            height: $header-simple-search-button-height;
            width: $header-simple-search-button-width;

            @include breakpoint(large) {
                @include svg-icon($header-simple-search-button-icon, $header-simple-search-button-icon-color--large, 100%, left, no-repeat, $usesvg: $header-simple-search-button-icon-usesvg, $color-change: true);

                height: $header-simple-search-button-height--large;
                width: $header-simple-search-button-width--large;
            }
        }
    }

    .c-simple-search__button-text {
        @include text-hide;
    }

    .c-simple-search__close-button {
        @include svg-icon($header-simple-search-close-button-icon, $header-simple-search-close-button-icon-color, $header-simple-search-close-button-icon-width $header-simple-search-close-button-icon-height, center, no-repeat, $usesvg: $header-simple-search-close-button-icon-usesvg);

        align-self: center;
        cursor: pointer;
        flex-shrink: 0;
        height: $header-simple-search-close-button-height;
        margin: $header-simple-search-close-button-margin;
        order: 2;
        width: $header-simple-search-close-button-width;

        @include breakpoint(large) {
            display: none;
        }
    }

    .c-simple-search__close-button-text {
        @include text-hide;
    }

    .c-simple-search__clear-button {
        @include svg-icon($header-simple-search-clear-button-icon, $header-simple-search-clear-button-icon-color, $header-simple-search-clear-button-icon-width $header-simple-search-clear-button-icon-height, center, no-repeat, $usesvg: $header-simple-search-clear-button-icon-usesvg);

        cursor: pointer;
        display: none;
        height: $header-simple-search-clear-button-height;
        margin: $header-simple-search-clear-button-margin;
        width: $header-simple-search-clear-button-width;

        @include breakpoint(large) {
            height: $header-simple-search-clear-button-height--large;
            margin: $header-simple-search-clear-button-margin--large;
            width: $header-simple-search-clear-button-width--large;
            background: $header-simple-search-clear-button-background--large;
        }

        &.m-active {
            display: block;
        }
    }

    .c-simple-search__clear-button-text {
        @include text-hide;
    }

    .c-simple-search.m-primary {
        @include breakpoint(large) {
            display: none;
        }

        .c-simple-search__input-group,
        .c-simple-search__results {
            @include pseudo-off-the-isle(inherit);

            &::before,
            &::after {
                z-index: $header-simple-search-input-z-index;
            }
        }
    }

    // Secondary styles for a search field
    .c-simple-search.m-secondary {
        position: relative;
        height: auto;
        display: $header-simple-search-secondary-display;
        justify-content: $header-simple-search-secondary-justify-content;
        padding: $header-simple-search-secondary-padding;

        @include breakpoint(large) {
            display: $header-simple-search-secondary-display--large;
            justify-content: $header-simple-search-secondary-justify-content--large;
            padding: $header-simple-search-secondary-padding--large;
            position: $header-simple-search-secondary-position--large;
        }

        .c-simple-search__input-group {
            background: $header-simple-search-secondary-input-group-background;
            width: $header-simple-search-secondary-input-group-width;

            @include breakpoint(medium down) {
                @include pseudo-off-the-isle(inherit);
            }

            @include breakpoint(large) {
                background: $header-simple-search-secondary-input-group-background--large;
                width: $header-simple-search-secondary-input-group-width--large;
            }
        }

        .c-simple-search__search-label {
            display: $header-simple-search-secondary-label-display;

            @include breakpoint(large) {
                display: $header-simple-search-secondary-label-display--large;
                font-size: $header-simple-search-secondary-label-font-size--large;
                color: $header-simple-search-secondary-label-color--large;
                line-height: $header-simple-search-secondary-label-line-height--large;
            }
        }

        .c-simple-search__field {
            border: $header-simple-search-secondary-field-border;
            background: $header-simple-search-secondary-field-background-color;
            font-size: $header-simple-search-secondary-field-font-size;
            padding: $header-simple-search-secondary-field-padding;
            height: $header-simple-search-secondary-field-height;

            @include breakpoint(large) {
                border: $header-simple-search-secondary-field-border--large;
                background: $header-simple-search-secondary-field-background-color--large;
                font-size: $header-simple-search-secondary-field-font-size--large;
                padding: $header-simple-search-secondary-field-padding--large;
                height: $header-simple-search-secondary-field-height--large;
            }

            &::placeholder {
                color: $header-simple-search-secondary-field-placeholder-color;

                @include breakpoint(large) {
                    color: $header-simple-search-secondary-field-placeholder-color--large;
                }
            }
        }

        .c-simple-search__button {
            &::after {
                width: $header-simple-search-secondary-button-width;

                @include breakpoint(large) {
                    width: $header-simple-search-secondary-button-width--large;
                }
            }
        }

        .c-simple-search__close-button {
            display: none;
        }

        .c-simple-search__results {
            position: $header-simple-search-secondary-results-position;
            top: $header-simple-search-secondary-results-top;
            #{$global-right}: $header-simple-search-secondary-results-right;

            @include breakpoint(medium down) {
                @include pseudo-off-the-isle(inherit);
            }

            @include breakpoint(large) {
                position: $header-simple-search-secondary-results-position--large;
                top: $header-simple-search-secondary-results-top--large;
                #{$global-right}: $header-simple-search-secondary-results-right--large;
            }
        }

        .c-simple-search__results-list {
            display: none;
        }
    }

    .c-simple-search.m-inline {
        background: $header-simple-search-inline-background;
        display: $header-simple-search-inline-display;
        height: auto;
        position: static;
        padding: $header-simple-search-inline-padding;

        @include breakpoint(large) {
            background: $header-simple-search-inline-background--large;
            display: none;
            padding: $header-simple-search-inline-padding--large;
        }

        .c-simple-search__input-group {
            background: $header-simple-search-inline-input-group-background;
            border-bottom: $header-simple-search-inline-input-group-border-bottom;

            @include breakpoint(large) {
                background: $header-simple-search-inline-input-group-background--large;
                border-bottom: $header-simple-search-inline-input-group-border-bottom--large;
            }
        }

        .c-simple-search__search-label {
            @include breakpoint(medium down) {
                color: $header-simple-search-inline-label-color--medium-down;
                #{$global-left}: $header-simple-search-inline-label-left--medium-down;
                max-width: calc(100% - #{$header-simple-search-close-button-width});
            }
        }

        .c-simple-search__field:focus + .c-simple-search__search-label,
        .c-simple-search__field:not(:placeholder-shown) + .c-simple-search__search-label {
            @include breakpoint(medium down) {
                &::before {
                    background-color: $header-simple-search-inline-label-background;
                }
            }
        }

        .c-simple-search__field {
            background: $header-simple-search-inline-field-background;
            font-size: $header-simple-search-inline-field-font-size;
            padding: $header-simple-search-inline-field-padding;
            order: 0;
            color: $header-simple-search-inline-field-color;

            @include breakpoint(large) {
                background: $header-simple-search-inline-field-background--large;
                font-size: $header-simple-search-inline-field-font-size--large;
                padding: $header-simple-search-inline-field-padding--large;
                color: $header-simple-search-inline-field-color--large;
            }
        }

        .c-simple-search__button {
            &::after {
                @include svg-icon($header-simple-search-inline-button-icon, $header-simple-search-inline-button-icon-color, 100%, left, no-repeat, $usesvg: $header-simple-search-inline-button-icon-usesvg);
            }
        }

        .c-simple-search__close-button {
            display: none;
        }

        .c-simple-search__results {
            position: absolute;
            #{$global-left}: 0;
            width: $header-simple-search-inline-results-width;
            padding: $header-simple-search-inline-results-padding;
            margin: $header-simple-search-inline-results-margin;

            @include breakpoint(large) {
                position: relative;
                width: $header-simple-search-inline-results-width--large;
                padding: $header-simple-search-inline-results-padding--large;
                margin: $header-simple-search-inline-results-margin--large;
            }
        }

        .c-simple-search__results-list {
            display: none;
        }
    }

    .c-simple-search.m-search-button-active {
        display: none;
        box-shadow: $header-simple-search-search-button-active-box-shadow;

        @include breakpoint(large) {
            box-shadow: $header-simple-search-search-button-active-box-shadow--large;
        }

        .c-simple-search__close-button {
            display: block;

            @include breakpoint(large) {
                display: none;
            }
        }
    }
}
