$loyalty-howto-section-padding: 0 !default;
$loyalty-howto-section-padding--large: rem-calc(0 50) !default;
$loyalty-howto-title-margin: rem-calc(0 0 20) !default;
$loyalty-howto-title-text-transform: uppercase !default;
$loyalty-howto-content-title-margin: rem-calc(40 0 10) !default;
$loyalty-howto-content-title-text-transform: capitalize !default;
$loyalty-howto-description-margin: rem-calc(0 0 50) !default;
$loyalty-howto-description-min-height--large: rem-calc(126) !default;

@mixin apaclayer-components-loyalty-howto {
    .c-loyalty-howto__title {
        margin: $loyalty-howto-title-margin;
        text-transform: $loyalty-howto-title-text-transform;
    }

    .c-loyalty-howto__content {
        padding: $loyalty-howto-section-padding;

        @include breakpoint(large) {
            padding: $loyalty-howto-section-padding--large;
        }
    }

    .c-loyalty-howto__content-title {
        margin: $loyalty-howto-content-title-margin;
        text-transform: $loyalty-howto-content-title-text-transform;
    }

    .c-loyalty-howto__description {
        margin: $loyalty-howto-description-margin;

        @include breakpoint(large) {
            min-height: $loyalty-howto-description-min-height--large;
        }
    }
}
