$loyalty-dashboardvoucher-title-font-size: rem-calc(20) !default;
$loyalty-dashboardvoucher-title-margin: rem-calc(0 0 20) !default;
$loyalty-dashboardvoucher-title-text-transform: uppercase !default;
$loyalty-dashboardvoucher-title-info-margin--large: rem-calc(0 0 0 16) !default;
$loyalty-dashboardvoucher-title-info-display: block !default;
$loyalty-dashboardvoucher-title-info-display--large: inline-block !default;

$loyalty-dashboardvoucher-cart-loyalty-rewards-tab-margin: rem-calc(16 0 0) !default;
$loyalty-dashboardvoucher-padding: rem-calc(20 20 1) !default;
$loyalty-dashboardvoucher-padding--large: rem-calc(20 35 1) !default;
$loyalty-dashboardvoucher-tools-width: 100% !default;

@mixin apaclayer-components-loyalty-dashboardvoucher {
    .c-loyalty-dashboardvoucher {
        padding: $loyalty-dashboardvoucher-padding;

        @include breakpoint(large) {
            padding: $loyalty-dashboardvoucher-padding--large;
        }

        .c-cart-loyalty__toggle-cta {
            display: none;
        }

        .c-cart-loyalty.m-extendable {
            .c-cart-loyalty__tools {
                pointer-events: none;
                cursor: default;
                width: $loyalty-dashboardvoucher-tools-width;
            }
        }

        .c-loyalty-dashboardvoucher__tabs-container {
            margin: $loyalty-dashboardvoucher-cart-loyalty-rewards-tab-margin;
        }
    }

    .c-loyalty-dashboardvoucher__title {
        font-size: $loyalty-dashboardvoucher-title-font-size;
        margin: $loyalty-dashboardvoucher-title-margin;
        text-transform: $loyalty-dashboardvoucher-title-text-transform;
    }

    .c-loyalty-dashboardvoucher__title-info {
        display: $loyalty-dashboardvoucher-title-info-display;

        @include breakpoint(large) {
            margin: $loyalty-dashboardvoucher-title-info-margin--large;
            display: $loyalty-dashboardvoucher-title-info-display--large;
        }
    }
}
