$loyalty-summary-padding: rem-calc(25 0) !default;
$loyalty-summary-padding--large: rem-calc(20 0) !default;

$loyalty-summary-promotion-text-align: center !default;
$loyalty-summary-promotion-padding--large: rem-calc(0 0 0 10) !default;

$loyalty-summary-points-expiry-font-size: rem-calc(11) !default;
$loyalty-summary-points-expiry-color: color(text-secondary) !default;

$loyalty-summary-points-available-font-size: rem-calc(14) !default;
$loyalty-summary-points-available-font-size--large: rem-calc(16) !default;
$loyalty-summary-points-available-padding--large: rem-calc(20 0 10) !default;
$loyalty-summary-points-available-display: block !default;

$loyalty-summary-points-total-font-size: rem-calc(20) !default;
$loyalty-summary-points-total-font-size--large: rem-calc(24) !default;
$loyalty-summary-points-total-height: 100% !default;

$loyalty-summary-points-text-padding: rem-calc(50 10) !default;
$loyalty-summary-points-text-padding--large: rem-calc(0 5) !default;

$loyalty-summary-promotion-text-font-size: rem-calc(12) !default;
$loyalty-summary-promotion-text-font-size--large: rem-calc(14) !default;
$loyalty-summary-promotion-text-height: 100% !default;
$loyalty-summary-promotion-text-padding: rem-calc(30 10) !default;
$loyalty-summary-promotion-text-padding--large: rem-calc(0 5) !default;

$loyalty-summary-banner-image-min-height: rem-calc(150) !default;

$loyalty-summary-points-disclaimer-margin: rem-calc(10 5) !default;
$loyalty-summary-points-disclaimer-display: block !default;

$loyalty-summary-banner-badge-margin: null !default;
$loyalty-summary-banner-badge-margin--large: $loyalty-summary-banner-badge-margin !default;

$loyalty-summary-banner-badge-padding: null !default;
$loyalty-summary-banner-badge-padding--large: $loyalty-summary-banner-badge-padding !default;

@mixin apaclayer-components-loyalty-summary {
    .c-loyalty-summary {
        padding: $loyalty-summary-padding;

        @include breakpoint(large) {
            padding: $loyalty-summary-padding--large;
        }
    }

    .c-loyalty-summary__promotion,
    .c-loyalty-summary__points {
        padding: 0;
        text-align: $loyalty-summary-promotion-text-align;

        @include breakpoint(large) {
            padding: $loyalty-summary-promotion-padding--large;
        }
    }

    .c-loyalty-summary__promotion {
        @include breakpoint(large) {
            margin: 0;
        }
    }

    .c-loyalty-summary__points-expiry {
        font-size: $loyalty-summary-points-expiry-font-size;
        color: $loyalty-summary-points-expiry-color;
    }

    .c-loyalty-summary__points-text {
        height: $loyalty-summary-points-total-height;
        padding: $loyalty-summary-points-text-padding;

        @include breakpoint(large) {
            padding: $loyalty-summary-points-text-padding--large;
        }
    }

    .c-loyalty-summary__points-available {
        font-size: $loyalty-summary-points-available-font-size;
        font-weight: bold;
        display: $loyalty-summary-points-available-display;

        @include breakpoint(large) {
            font-size: $loyalty-summary-points-available-font-size--large;
            padding: $loyalty-summary-points-available-padding--large;
        }
    }

    .c-loyalty-summary__points-total {
        font-size: $loyalty-summary-points-total-font-size;

        @include breakpoint(large) {
            font-size: $loyalty-summary-points-total-font-size--large;
        }
    }

    .c-loyalty-summary__promotion-text {
        font-size: $loyalty-summary-promotion-text-font-size;
        height: $loyalty-summary-promotion-text-height;
        padding: $loyalty-summary-promotion-text-padding;

        @include breakpoint(large) {
            font-size: $loyalty-summary-promotion-text-font-size--large;
            padding: $loyalty-summary-promotion-text-padding--large;
        }
    }

    .c-loyalty-summary__banner-image {
        @include breakpoint(medium down) {
            min-height: $loyalty-summary-banner-image-min-height;
        }
    }

    .c-loyalty-summary__points-disclaimer {
        margin: $loyalty-summary-points-disclaimer-margin;
        display: $loyalty-summary-points-disclaimer-display;
    }

    .c-loyalty-summary__banner-badge {
        margin: $loyalty-summary-banner-badge-margin;
        padding: $loyalty-summary-banner-badge-padding;
        
        @include breakpoint(large) {
            margin: $loyalty-summary-banner-badge-margin--large;
            padding: $loyalty-summary-banner-badge-padding--large;
        }
    }
}
