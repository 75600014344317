//stylelint-disable
@mixin my-layer-checkout-page {
    .page-checkout,
    .page-onestepcheckout {
        .formfield_phone .textinput {
            &::placeholder {
                color: #5f5f5f;
            }
        }
    }
}