$loyalty-progress-bar-wrapper-margin: rem-calc(50 0) !default;
$loyalty-progress-bar-item-z-index: 2 !default;
$loyalty-progress-bar-item-completed-z-index: 3 !default;
$loyalty-progress-bar-tier-counter-z-index: 5 !default;
$loyalty-progress-bar-item-completed-border: rem-calc(3) solid color(primary-active) !default;
$loyalty-progress-bar-item-completed-border-color: color(primary-active) !default;
$loyalty-progress-bar-item-border-border-bottom: rem-calc(3) solid color(border) !default;
$loyalty-progress-bar-item-completed-background-color: color(primary-active) !default;
$loyalty-progress-bar-item-background-color: color(border) !default;
$loyalty-progress-bar-item-tier-title-font: 700 #{rem-calc(12)} $font-primary !default;
$loyalty-progress-bar-item-tier-title-font--large: 700 #{rem-calc(16)} / 1.45 $font-primary !default;
$loyalty-progress-bar-item-tier-title-color: color(text-secondary) !default;
$loyalty-progress-bar-item-tier-title-opacity: 0.5;
$loyalty-progress-bar-item-tier-title-completed-opacity: 1;
$loyalty-progress-bar-item-tier-label-margin: rem-calc(-18) !default;
$loyalty-progress-bar-item-tier-label-margin--large: rem-calc(-23) !default;

$loyalty-progress-bar-tier-counter-width: rem-calc(14) !default;
$loyalty-progress-bar-tier-counter-height: rem-calc(14) !default;
$loyalty-progress-bar-tier-counter-border-radius: rem-calc(20) !default;
$loyalty-progress-bar-tier-counter-margin: rem-calc(14) 0 rem-calc(10) 0 !default;
$loyalty-progress-bar-tier-counter-border: 1px solid color(border) !default;

@mixin apaclayer-components-loyalty-progress-bar {
    .c-loyalty-progress-bar__wrapper {
        display: flex;
        justify-content: space-between;
        margin: $loyalty-progress-bar-wrapper-margin;
    }

    .c-loyalty-progress-bar__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        &:not(:first-child) {
            &::before {
                position: absolute;
                content: "";
                border-bottom: $loyalty-progress-bar-item-border-border-bottom;
                width: 100%;
                top: 20px;
                left: -50%;
                z-index: $loyalty-progress-bar-item-z-index;
            }
        }

        &.m-completed {
            &::before {
                position: absolute;
                content: "";
                border-bottom: $loyalty-progress-bar-item-completed-border;
                width: 100%;
                top: 20px;
                left: 50%;
                z-index: $loyalty-progress-bar-item-completed-z-index;
            }

            .c-loyalty-progress-bar__tier-counter {
                background-color: $loyalty-progress-bar-item-completed-background-color;
                border-color: $loyalty-progress-bar-item-completed-border-color;
            }

            .c-loyalty-progress-bar__tier-title {
                opacity: $loyalty-progress-bar-item-tier-title-completed-opacity;
            }

            &.m-active {
                &::before {
                    content: none;
                }
            }
        }

        .c-loyalty-progress-bar__tier-counter {
            position: relative;
            z-index: $loyalty-progress-bar-tier-counter-z-index;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $loyalty-progress-bar-tier-counter-width;
            height: $loyalty-progress-bar-tier-counter-height;
            border-radius: $loyalty-progress-bar-tier-counter-border-radius;
            margin: $loyalty-progress-bar-tier-counter-margin;
            background-color: $loyalty-progress-bar-item-background-color;
            border: $loyalty-progress-bar-tier-counter-border;
        }

        .c-loyalty-progress-bar__tier-title {
            font: $loyalty-progress-bar-item-tier-title-font;
            color: $loyalty-progress-bar-item-tier-title-color;
            opacity: $loyalty-progress-bar-item-tier-title-opacity;

            @include breakpoint(large) {
                font: $loyalty-progress-bar-item-tier-title-font--large;
            }
        }

        .c-loyalty-progress-bar__tier-label {
            margin-top: $loyalty-progress-bar-item-tier-label-margin;
            font: $loyalty-progress-bar-item-tier-title-font;

            @include breakpoint(large) {
                font: $loyalty-progress-bar-item-tier-title-font--large;
                margin-top: $loyalty-progress-bar-item-tier-label-margin--large;
            }
        }
    }
}